// API URL
export const ROOT_URL = 'https://api.msmusic.com.au/api';
// export const ROOT_URL = 'http://stg-api.msmusic.com.au/api';
// export const ROOT_URL = 'http://localhost:3090/api';

// Portal
export const GET_SCHOOL = 'get_school';
export const UPDATE_SCHOOL = 'update_school';
export const SIGN_OUT = 'sign_in';
export const SIGN_IN = 'sign_out';
export const SET_CONTACT = 'set_contact';
export const SET_STUDENT = 'set_student';
export const SET_STUDENTS = 'set_students';
export const GET_STUDENT_GROUPS = 'get_student_groups';
export const GET_EVENTS = 'get_events';
export const GET_DOCUMENTS = 'get_documents';
export const ADD_STUDENT = 'add_student';
export const GET_FORM = 'get_form';
export const GET_NEW_FORM = 'get_new_student_form';
export const GET_ENROL_FORM = 'get_enrolment_form';
export const SET_ENROLMENTS = 'set_enrolments';
export const REMOVE_ENROLMENT = 'remove_enrolments';
export const SET_INVOICE = 'set_invoice';
export const PWD_TOKEN = 'pwd_token';
export const GET_GRADES = 'get_grades';
export const SET_FORM_OPTIONS = 'set_form_options';
export const GET_ENROLMENTS = 'get_enrolments';
export const TOGGLE_WAIT = 'toggle_wait';
export const GET_STUDENT_INFO = 'get_student_info';
export const SET_CUSTOM_FORMS = 'set_custom_forms';
export const GET_CUSTOM_FORM = 'get_custom_form';
export const REMOVE_CUSTOM_FORM = 'remove_custom_form';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
