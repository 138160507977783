import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import * as actions from "../../actions/forms";
import CustomForm from "./custom-form-modal";
import CustomFormList from "./custom-form-list";

class CustomFormsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      student: {},
      form: {},
      studentIndex: 0,
      formIndex: 0,
      page: 'select'
    };
  }

 componentWillReceiveProps(nextProps) {
        if (nextProps.contact.customForms !== this.props.contact.customForms) {
          if (nextProps.contact.customForms.filter(item => item.customForms.length > 0).length === 0) {
              this.props.closeModal();
            } else {
              this.setState({ page: 'select' });
            }
        }
     }

  selectForm = (studentIndex, formIndex, student, form) => {
    this.setState({ studentIndex, formIndex, student, form, page: 'form' });
  };

  onCancel = () => {
    this.setState({ studentIndex: 0, formIndex: 0, student: {}, form: {}, page: 'select' });
  };

  saveForm = values => {
    const { studentIndex, formIndex, form, student } = this.state;
    const studentObject = JSON.parse(JSON.stringify(this.props.customForms.slice(studentIndex, studentIndex + 1)[0]));
    studentObject.customForms.splice(formIndex, 1);

    this.props.saveCustomForm(values, form.id, student.id, studentIndex, studentObject);
  };

 render() {
    const { customForms } = this.props;
    const { page, form, student } = this.state;

    if (!customForms) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        {(page === 'select') 
          ? <CustomFormList 
                selectForm={this.selectForm} 
                customForms={customForms} />
          : <CustomForm 
                student={student}
                studentForm={form}
                saveForm={this.saveForm}
                onCancel={this.onCancel} />
        }
      </div>
    );
  }
}

CustomFormsPage.propTypes = {
  getCustomForm: PropTypes.func.isRequired,
  saveCustomForm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  customForms: PropTypes.array.isRequired,
  selectedStudent: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    contact: state.contact,
    selectedStudent: state.selectedStudent
  };
}

export default connect(mapStateToProps, actions)(CustomFormsPage);
