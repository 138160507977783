import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import DisplayMsg from '../../common/display-message';
import * as actions from '../../../actions/forms';
import ParentAgreement from './parent-agreement-form';
import ParentDetails from './parent-details-form';
import StudentDetails from './student-details-form';
import RegistrationDetails from './registration-details-form';

class NewStudents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };

    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.saveNewStudent = this.saveNewStudent.bind(this);
  }

  componentDidMount() {
    this.props.getNewStudentForm(this.props.school._id);
  }

  saveNewStudent = (values) => {
    this.props.newStudentRegistration(values, this.props.school._id, this.props.history);
  };

  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  render() {
    const { forms, school } = this.props;
    const { page } = this.state;
    if (forms === 'not found') {
      return <Redirect to='/' />;
    }
    if (!forms.newRegActive) return <div>Loading...</div>;

    // Add questions to redux form as hidden values
    const initialValues = {
      school: school.name,
      student: { photoConsent: true, registration: { questions: {} } },
    };
    forms.questions.map((question) => {
      initialValues.student.registration.questions[question._id] = {
        question: question.questionText,
      };
    });

    return (
      <div>
        <DisplayMsg />
        <div className='row'>
          <div className='col-sm-12'>
            <div className='navigation-style clearfix' style={{ backgroundColor: school.schoolColour }}>
              <a className={school.hasLogo ? 'nav-logo pull-left' : 'nav-logo pull-left no-logo'} href='#'>
                {school.hasLogo && <img src={`https://portal.msmusic.com.au/${school._id}.png`} alt='logo' />}{' '}
                {!school.hideName && school.name}
              </a>
              <ul className='pull-right list-unstyled'>
                <li>
                  <Link to='/'>
                    <i className='fa fa-hand-o-left'></i>Back
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-lg-offset-3 col-lg-6'>
            <div className='panel panel-default'>
              <div className='panel-heading'>New Student Registration</div>
              <div className='panel-body'>
                {page === 1 && (
                  <ParentAgreement onSubmit={this.nextPage} agreement={forms} initialValues={initialValues} />
                )}
                {page === 2 && <ParentDetails previousPage={this.previousPage} onSubmit={this.nextPage} />}
                {page === 3 && (
                  <StudentDetails
                    previousPage={this.previousPage}
                    onSubmit={this.nextPage}
                    grades={school.selectors.grades}
                  />
                )}
                {page === 4 && (
                  <RegistrationDetails
                    previousPage={this.previousPage}
                    formDetails={forms}
                    onSubmit={this.saveNewStudent}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <footer>
          <div className='copyrights'>
            © Copyrights 2017{' '}
            <a href='www.myschoolmusic.com.au/' target='_blank'>
              My School Music
            </a>{' '}
            All Rights Reserved.
          </div>
        </footer>
      </div>
    );
  }
}

NewStudents.propTypes = {
  getNewStudentForm: PropTypes.func.isRequired,
  newStudentRegistration: PropTypes.func.isRequired,
  forms: PropTypes.object,
  school: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    school: state.portal.school,
    forms: state.portal.forms.newReg,
  };
}

export default connect(mapStateToProps, actions)(NewStudents);
