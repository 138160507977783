import React from 'react';
import PropTypes from 'prop-types';

const renderFiles = (documents, downloadFile) => {
    return documents.map((document, index) => {
        return (
            <li key={index}>
              <div className="client-info clearfix">
                <div className="client-name pull-left">
                  <p>{document.fileName}</p>
                  <div className="client-loc blueColor">
                    <span>{document.comment}</span>
                  </div>
                </div>
                <div className="find-more pull-right">
                  <a href="#" onClick={() => downloadFile(document)} >
                    <i className="fa fa-cloud-download fa-lg"></i>
                  </a>
                </div>
              </div>
            </li>
        );
    });
};

const DocumentsModule = (props) => {

  const { documents, downloadFile } = props;

    return (
        <div className="clients-widget xs-m-30">
            <div className="widget-head blueBG">
              <h3>Documents</h3>
            </div>
            <div className="widget-content">
              <ul className="list-unstyled">
                  {(documents.length > 0) ? renderFiles(documents, downloadFile) : <li key="1">No document have been shared</li>}
              </ul>
            </div>
          </div>
    );
};

DocumentsModule.propTypes = {
    documents: PropTypes.array.isRequired,
    downloadFile: PropTypes.func.isRequired
};

export default DocumentsModule;