import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required, email, number, none } from '../common/validation';
import DropdownList from 'react-select';
import { Link } from 'react-router-dom';

const dropdownData = data => data.map(obj => ({ value: obj }));
   
const renderDropdownList = ({ input, label, meta: { touched, error }, ...rest }) => (
    <div>
        <label className="col-sm-3 control-label">{label}</label>
        <div className="col-sm-8 has-error">
            <DropdownList {...input} {...rest} onBlur={() => input.change} />
            { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
        </div>
    </div>
    );

const renderQuestionDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
    <div className="has-error">
        <DropdownList {...input} {...rest} onBlur={() => input.change} />
            { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
    );

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div className={(touched && error) ? 'has-error' : ''}>
    <label className="col-sm-3 control-label">{label}</label>
    <div className="col-sm-8">
         <input className="form-control" {...input} type={type}/>
            { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
  </div>
);

const renderComment = ({ input, label }) => <input className="form-control" {...input} placeholder={label} type="text" />;


const instrumnetPrefences = (preference, instruments) => (
    <div className="form-group" key={preference}>
        <Field name={`registration.preference.${preference}`} type="text" component={renderDropdownList} options={instruments} valueKey="label" simpleValue={true} label={`Preference ${preference + 1}`} validate={(preference === 0) ? required : none} />
    </div>
);

const questionComment = (question) => (
    <div className="col-sm-12 padding-top-half">
        <Field name={`questions.${question._id}.comment`} type="text" component={renderComment} label={question.commentText} />
    </div>
);

const registrationQuestions = (question) => (
    <div key={question._id}>
        <div className="form-group">
        <label className="col-sm-9 padding-top-one">{ question.questionText }</label>
            <div className="col-sm-3">
                <Field name={`questions.${question._id}.answer`} type="text" clearable={(question.required) ? false : true} component={renderQuestionDropdownList} options={dropdownData(question.answers)} labelKey="value" simpleValue={true} validate={(question.required) ? required : none} />
            </div>
            { (question.commentAllowed) && questionComment(question) }
        
        </div>
        <hr />
    </div>
);

 const SingleNewStudentsForm = (props) => {
    const { handleSubmit, submitting, regDetails } = props;

    return (
        <form onSubmit={handleSubmit} className="form-horizontal">
            <h4 className="blueColor">Student Details</h4>
            <div className="form-group">
                <Field name="student.firstName" type="text" component={renderField} label="First Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="student.lastName" type="text" component={renderField} label="Last Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="student.gender" type="text" clearable={false} component={renderDropdownList} options={[{ value: 'Male'}, { value: 'Female'}]} labelKey="value" simpleValue={true} validate={required} label="Gender" />   
            </div>
            <div className="form-group">
                <Field name="student.grade" type="text" clearable={false} component={renderDropdownList} options={props.grades} valueKey="label" simpleValue={true} validate={required} label="Grade" />   
            </div>

            <hr />
            <h4 className="blueColor">Parent Details</h4>
            <div className="form-group">
                <Field name="contact.firstName" type="text" component={renderField} label="First Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="contact.lastName" type="text" component={renderField} label="Last Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="contact.email" type="email" component={renderField} label="Email" validate={[required, email]} />
            </div>
            <div className="form-group">
                <Field name="contact.mobile" type="text" component={renderField} label="Mobile" validate={[required, number]} />
            </div>
            <div className="form-group">
                <Field name="contact.address" type="text" component={renderField} label="Address" />
            </div>

            <hr />
            <h4 className="blueColor">Registration Details</h4>
            <div className="form-group">
                <Field name="registration.group" type="text" clearable={false} component={renderDropdownList} options={regDetails.newRegOptions.ensembleList} simpleValue={true} validate={required} label="Ensemble" />   
            </div>
             <hr />
            <h5>Instrument Preference</h5>
            {Array.apply(null, Array(regDetails.newRegOptions.numberOfPreferences*1)).map((item, i) => instrumnetPrefences(i, regDetails.newRegOptions.instrumentList))}
            <hr />
            <h5>Registration Questions</h5>
            <br />
                {regDetails.questions.map(question => registrationQuestions(question))}
            <div className="form-actions clearfix">
                <div className="submit-form">
                    <button type="submit" className="submit-btn" disabled={submitting}>Register</button>&nbsp;&nbsp;&nbsp;
                    <Link to="/" >Cancel</Link>  
                </div>
           </div>  
           <div className="padding-top-one">
            {props.submitFailed && <div className="alert custom-dark-alert-warning"><strong>Form not submitted</strong> - Check that all required fields have been completed</div>}
           </div>
        </form>
    );
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.func
};

renderQuestionDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  validate: PropTypes.func
};

renderComment.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired
};

SingleNewStudentsForm.propTypes = {
  ...propTypes
};

export default reduxForm({
    form: 'SingleNewStudentsForm'
})(SingleNewStudentsForm);