import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { Checkbox } from '@blueprintjs/core';
import { required, none } from '../common/validation';

const dropdownData = (data) => data.map((obj) => ({ value: obj }));

const renderQuestionDropdownList = ({ input, multiOptions, meta: { touched, error }, ...rest }) => (
  <div className='has-error'>
    <DropdownList {...input} {...rest} onBlur={() => input.change} multi={multiOptions} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div className={touched && error ? 'has-error' : ''}>
    <label className='col-sm-3 control-label'>{label}</label>
    <div className='col-sm-8'>
      <input className='form-control' {...input} type={type} />
      {touched && error && (
        <div className='admin-label text-right margin-top-quarter'>
          <i className='redBG'>{error}</i>
        </div>
      )}
    </div>
  </div>
);

const renderComment = ({ input, label }) => (
  <input className='form-control' {...input} placeholder={label} type='text' />
);

const questionComment = (question) => (
  <div className='col-sm-12 padding-top-half'>
    <Field
      name={`questions.${question._id}.comment`}
      type='text'
      component={renderComment}
      label={question.commentText}
    />
  </div>
);

const renderQuestions = (question) => (
  <div key={question._id}>
    <div className='form-group'>
      <div className='col-sm-9 padding-top-one'>{question.questionText}</div>
      <div className='col-sm-3'>
        <Field
          name={`questions.${question._id}.answer`}
          type='text'
          clearable={question.required ? false : true}
          component={renderQuestionDropdownList}
          options={dropdownData(question.answers)}
          labelKey='value'
          simpleValue={true}
          multiOptions={question.multiOptions}
          validate={question.required ? required : none}
        />
      </div>
      {question.commentAllowed && questionComment(question)}
    </div>
    <hr />
  </div>
);

const renderCheckbox = ({ input }) => (
  <label className='pt-control pt-checkbox pt-large text-left'>
    <Checkbox checked={input.value} onChange={input.onChange} label='&nbsp;&nbsp;Photo consent' />
    <p style={{ fontSize: 10, paddingLeft: 30, paddingRight: 30, color: 'gray' }}>
      I give permission to the Music Program to take photos / videos of my child and to upload promotional photos /
      videos to newsletters, the P&C website and the school website
    </p>
  </label>
);

const EnrolmentQuestions = (props) => {
  const { handleSubmit, submitting, questions, previousPage, closeModal } = props;

  return (
    <form onSubmit={handleSubmit} className='form-horizontal'>
      <h5 className='blueColor text-center'>Registration Questions</h5>
      <br />
      {questions.map((question) => renderQuestions(question))}

      <div className='form-group'>
        <Field name='photoConsent' id='photoConsent' component={renderCheckbox} />
      </div>
      <hr />

      <div className='row'>
        <div className='col-xs-6'>
          <button type='button' className='btn btn-primary' onClick={previousPage}>
            Previous
          </button>
        </div>
        <div className='col-xs-6 text-right'>
          {closeModal && (
            <button type='button' className='btn btn-default' onClick={closeModal}>
              Close
            </button>
          )}
          &nbsp;&nbsp;
          <button type='submit' className='btn btn-success' disabled={submitting}>
            Register
          </button>
        </div>
      </div>
    </form>
  );
};

renderQuestionDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  validate: PropTypes.func,
};

renderComment.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

EnrolmentQuestions.propTypes = {
  ...propTypes,
};

export default reduxForm({
  form: 'enrolment',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(EnrolmentQuestions);
