import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/forms';
import UpdateStudentForm from './update-student-form';

class UpdateStudentPage extends Component {

    constructor(props, context) {
        super(props, context);
    }

    componentWillMount() {
        this.props.getStudentInfo(this.props.studentId);
    }

    updateStudent = (student) => {
        this.props.saveStudentInfo(student, this.props.studentId);
        this.props.closeModal();
    }

    render() {
        const { student, grades } = this.props;

        return (
          <div>
            <UpdateStudentForm initialValues={student} onSubmit={this.updateStudent} grades={grades} closeModal={this.props.closeModal} />
          </div>
        );
    }
}

UpdateStudentPage.propTypes = {
  studentId: PropTypes.string.isRequired,
  student: PropTypes.object,
  grades: PropTypes.array.isRequired,
  selectors: PropTypes.object,
  saveStudentInfo: PropTypes.func.isRequired,
  getStudentInfo: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        student: state.selectedStudent.studentInfo,
        grades: state.portal.school.grades
    };
}

export default connect(mapStateToProps, actions)(UpdateStudentPage);