import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

moment.locale("en-au");

class Invoice extends Component {
  render() {
    const { schoolInv, studentInv } = this.props;

    const header = (schoolInv, studentInv, id) => {
      if (schoolInv.hasHeaderImage)
        return (
          <tr>
            <td className='title'>
              <img src={`https://portal.msmusic.com.au/invh${id}.png?lastmod=${schoolInv.hasHeaderImage}`} alt='Invoice Header' />
            </td>
            <td>
              Invoice #: {studentInv.invoiceNumber}
              <br />
              Created: {moment(studentInv.invoiceDate).format("ll")}
              <br />
              Due: {moment(studentInv.dueDate).format("ll")}
            </td>
          </tr>
        );

      return (
        <tr>
          <td className='title'>
            <div dangerouslySetInnerHTML={{ __html: schoolInv.invoiceHeader }} />
          </td>
          <td>
            Invoice #: {studentInv.invoiceNumber}
            <br />
            Created: {moment(studentInv.invoiceDate).format("ll")}
            <br />
            Due: {moment(studentInv.dueDate).format("ll")}
          </td>
        </tr>
      );
    };

    const renderItems = (items) => {
      if (!items) {
        return null;
      }

      return items.map((item, index) => {
        return (
          <tr className='item' key={index}>
            <td>{item.description}</td>
            <td>${item.amount && item.amount.toFixed(2)}</td>
          </tr>
        );
      });
    };

    return (
      <div className='invoice-box'>
        <table cellPadding='0' cellSpacing='0'>
          <tbody>
            <tr className='top'>
              <td colSpan='2'>
                <table>
                  <tbody>{header(schoolInv, studentInv, studentInv.schoolId)}</tbody>
                </table>
              </td>
            </tr>

            <tr className='information'>
              <td colSpan='2'>
                <table>
                  <tr>
                    <td>{schoolInv.hasHeaderImage && <div dangerouslySetInnerHTML={{ __html: schoolInv.invoiceHeader }} />}</td>
                    <td>Invoice For: {studentInv.invoiceTo}</td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr className='heading'>
              <td>Item</td>
              <td>Price</td>
            </tr>

            {renderItems(studentInv.invoiceItems)}

            <tr className='total'>
              <td>&nbsp;</td>
              <td>Total: ${studentInv.amount.toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan='2'>{studentInv.invoiceComment && <div className="card margin-top-one"><div className="card-block">{studentInv.invoiceComment}</div></div>}</td>
            </tr>
            <tr>
              <td colSpan='2'>
                <div dangerouslySetInnerHTML={{ __html: schoolInv.invoiceFooter }} />
              </td>
            </tr>
            <tr>
              <td colSpan='2'>
                {schoolInv.hasFooterImage && (
                  <img
                    src={`https://portal.msmusic.com.au/invf${studentInv.schoolId}.png?lastmod=${schoolInv.hasFooterImage}`}
                    alt='Invoice Footer'
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

Invoice.propTypes = {
  schoolInv: PropTypes.object.isRequired,
  studentInv: PropTypes.object.isRequired,
};

export default Invoice;
