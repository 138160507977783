import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions';

class Verify extends Component {

    componentDidMount() {
        this.props.verifyEmail(this.props.contactId, this.props.history);
    }
  
    render() {

        return (
        <div>Verifing...</div>
        );
    }
}

Verify.propTypes = {
    contactId: PropTypes.string.isRequired,
    verifyEmail: PropTypes.func.isRequired

};

function mapStateToProps(state, ownProps) {
    return {
        contactId : ownProps.match.params.id
    };
}

export default connect(mapStateToProps, actions)(Verify);

