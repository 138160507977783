import { SIGN_IN, SIGN_OUT, ADD_STUDENT, SET_ENROLMENTS, REMOVE_ENROLMENT, SET_CONTACT, 
    SET_CUSTOM_FORMS, REMOVE_CUSTOM_FORM, PWD_TOKEN, UPDATE_CONTACT } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.contact, action) {

    switch(action.type) {

         case SIGN_IN:
             return action.payload;

        case SET_ENROLMENTS:
             return { ...state, enrolments: action.payload };

        case SET_CUSTOM_FORMS:
             return { ...state, customForms: action.payload };

        case UPDATE_CONTACT:
             return { ...state, ...action.payload };

        case SET_CONTACT:
             return { ...state, contact1: action.contact1, contact2: action.contact2, emergency: action.emergency };

        case REMOVE_ENROLMENT:
             return { ...state, enrolments: [...state.enrolments.slice(0, action.payload), ...state.enrolments.slice(action.payload + 1, state.enrolments.length)] };

        case REMOVE_CUSTOM_FORM:
             return { ...state, customForms: 
                [...state.customForms.slice(0, action.studentIndex),
                    action.student,
                    ...state.customForms.slice(action.studentIndex + 1, state.customForms.length)] };
             
        case ADD_STUDENT:
            return { ...state, students: [...state.students, action.payload] };

        case PWD_TOKEN:
            return { ...state, resetPassword: action.payload };

        case SIGN_OUT:
             return { ...state, signin: false };

        default:
            return state;
    }
}