import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../actions";
import ContactForm from "./registration-form";

class PortalRegister extends Component {
  componentDidMount() {
    this.props.getRegistrationDetails(this.props.studentId, this.props.history);
  }

  saveRegistration = (values) => {
    this.props.saveRegistration(
      values,
      this.props.school,
      this.props.studentId,
      this.props.history
    );
  };

  render() {
    const { school, contact } = this.props;

    if (!contact.contact1.email) {
      return <div>&nbsp;</div>;
    }

    return (
      <div>
        <div className='row'>
        <div className="col-sm-12">
            <div className="navigation-style clearfix" style={{'backgroundColor': school.schoolColour}}>
                <a className={(school.hasLogo) ? 'nav-logo pull-left' : 'nav-logo pull-left no-logo'} href="#">{(school.hasLogo) && <img src={`https://portal.msmusic.com.au/${school._id}.png`} alt="logo"/>} {(!school.hideName) && school.name}</a>
            </div>
        </div>
        </div>
        <div className='padding-top-two col-sm-10 col-sm-offset-1 col-lg-8 col-lg-offset-2'>
          <div className='panel panel-default'>
            <div className='panel-heading'>Portal Registration</div>
            <div className='panel-body'>
              <ContactForm
                initialValues={contact}
                onSubmit={this.saveRegistration}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PortalRegister.propTypes = {
  studentId: PropTypes.string.isRequired,
  contact: PropTypes.object.isRequired,
  school: PropTypes.object.isRequired,
  getRegistrationDetails: PropTypes.func.isRequired,
  saveRegistration: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    studentId: ownProps.match.params.id,
    contact: state.contact,
    school: state.portal.school,
  };
}

export default connect(mapStateToProps, actions)(PortalRegister);
