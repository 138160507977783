import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import * as actions from '../../../actions';
import Invoice from './invoice';

class InvoicePage extends Component {

    componentWillMount() {
        this.props.getInvoice(this.props.id);
    }

    render() {
        const { schoolInv, studentInv } = this.props;

        if (!studentInv) {
            return (<div>Loading...</div>);
        }

        return (
            <div className="invoice-container">
               <div className="text-right print-button">
                    <ReactToPrint
                        trigger={() => <button type="button" className="btn btn-primary btn-sm rounded">Print</button>}
                        content={() => this.componentRef}
                    />
                </div> 
               
               <Invoice ref={el => (this.componentRef = el)} schoolInv={schoolInv} studentInv={studentInv} />
            </div> 
        );
    }
}

InvoicePage.propTypes = {
    schoolInv: PropTypes.object,
    studentInv: PropTypes.object,
    params: PropTypes.object.isRequired,
    id: PropTypes.string,
    getInvoice: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    schoolInv: state.portal.schoolInv,
    studentInv: state.portal.studentInv,
    id: ownProps.match.params.id
  };
}

export default connect(mapStateToProps, actions)(InvoicePage);

