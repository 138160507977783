import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import * as actions from "../../actions/forms";
import SelectEnrolment from "./select-student";
import EnrolmentInfo from "./re-enrolment-form-1";
import EnrolmentQuestions from "./re-enrolment-form-2";
import EnrolmentWidthdraw from "./re-enrolement-withdraw";

class ReEnrolmentPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      reenrole: false,
      student: {},
      studentIndex: 0
    };

    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.saveReEnrolment = this.saveReEnrolment.bind(this);
    this.selectStudent = this.selectStudent.bind(this);
  }

  componentWillMount() {
    this.props.getEnrolmentForm(this.props.selectedStudent.schoolId);
    if (this.props.contact.enrolments.length == 1) {
        this.setState({ student: this.props.contact.enrolments[0], studentIndex: 0 });
    } else {
        this.setState({ page: 'select' });
    }
  }
 
 componentWillReceiveProps(nextProps) {
        if (nextProps.contact.enrolments !== this.props.contact.enrolments) {
          if (nextProps.contact.enrolments.length === 0) {
              this.props.closeModal();
            } else {
              this.setState({ page: 'select' });
            }
        }
     }

  selectStudent = (index) => {
    this.setState({ student: this.props.contact.enrolments[index], studentIndex: index, page: 1 });
  };

  saveReEnrolment = values => {
    this.props.saveReEnrolment(values, this.state.student, this.state.studentIndex);
  };

  nextPage(values) {
    if (values.reenrole === "true") {
      this.setState({ page: this.state.page + 1, reenrole: true });
    } else {
      this.setState({ page: this.state.page + 1, reenrole: false });
    }
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  render() {
    const { enrolments, forms, closeModal } = this.props;
    const { page, reEnrole, student } = this.state;

    if (!forms) {
      return <div>Loading...</div>;
    }

    // Add questions to redux form as hidden values
    const initialValues = { questions: {}, photoConsent: true };
    forms.questions.map(question => {
      initialValues.questions[question._id] = { question: question.questionText };
    });

    return (
      <div>
        {page === "select" && <SelectEnrolment selectStudent={this.selectStudent} students={enrolments} />}
        {page === 1 && <EnrolmentInfo onSubmit={this.nextPage} initialValues={initialValues} enrolment={forms} student={student} closeModal={closeModal} />}
        {page === 2 && !this.state.reenrole && <EnrolmentWidthdraw onSubmit={this.saveReEnrolment} enrolment={forms} student={student} previousPage={this.previousPage}  />}
        {page === 2 && this.state.reenrole && <EnrolmentQuestions 
            previousPage={this.previousPage} 
            onSubmit={this.saveReEnrolment} 
            reEnrole={reEnrole} 
            questions={forms.questions} />}
        {}
      </div>
    );
  }
}

ReEnrolmentPage.propTypes = {
  getEnrolmentForm: PropTypes.func.isRequired,
  saveReEnrolment: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  enrolments: PropTypes.array.isRequired,
  selectedStudent: PropTypes.object.isRequired,
  forms: PropTypes.object,
  contact: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    forms: state.portal.forms.reEnrolment,
    contact: state.contact,
    selectedStudent: state.selectedStudent
  };
}

export default connect(mapStateToProps, actions)(ReEnrolmentPage);
