import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import EventCalendar from './events-cal';
import { Modal } from 'react-bootstrap';
import CalEvent from './cal-event';

class Events extends Component {

     constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            selectedEvent: {}
        };
        
    }

    openModal = (event) => {
         this.setState({ showModal: true, selectedEvent: event });
    };

    closeModal = () => {
         this.setState({ showModal: false, selectedEvent: {} });
    };

    render() {
        const { events, contact } = this.props;

        if (!contact.signin) {
            return <Redirect to="/" />;
          }

        return (
        <div>
            <div>
                <EventCalendar events={events} openEvent={this.openModal} />
            </div>
            <Modal show={this.state.showModal} bsSize="sm" onHide={this.closeModal}>
                <Modal.Header bsClass="modal-header primary" closeButton>
                    <Modal.Title>Event Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="clearfix form-padding">
                        <CalEvent event={this.state.selectedEvent} />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
        );
    }
}

Events.propTypes = {
    events: PropTypes.array.isRequired,
    contact: PropTypes.object.isRequired
};

function mapStateToProps(state) {

    return {
       events: state.portal.events,
       contact: state.contact
    };
}

export default connect(mapStateToProps)(Events);

