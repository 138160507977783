import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../../actions';
import PasswordForm from './password-form';
import Header from '../common/header';
import Footer from '../common/footer';

class UpdatePassword extends Component {

    updatePassword = (password) => {
       this.props.updatePassword(password);
   }
     
    render() {
        const { contact } = this.props;

        if (!contact.signin) {
            return <Redirect to="/" />;
          }

        return (
        <div>
            <div className="main-content">
                <Header />
                <div className="page-links">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">Contact Details</li>
                        <li className="breadcrumb-item pull-right">Welcome {contact.contactDetails.contact1.firstName}</li>
                    </ol>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="panel panel-primary">
                                <div className="panel-heading">Update Password</div>
                                <div className="panel-body">
                                   <PasswordForm onSubmit={this.updatePassword} />
                                </div>
                            </div>
                         
                    </div>
                </div>
                <br /><br />
            </div>
            <Footer/>
        </div>
        );
    }
}

UpdatePassword.propTypes = {
    contact: PropTypes.object.isRequired,
    updatePassword: PropTypes.func.isRequired
};

function mapStateToProps(state) {

    return {
       contact: state.contact
    };
}

export default connect(mapStateToProps, actions)(UpdatePassword);

