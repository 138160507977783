import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import SignInForm from './signin-form';
import { Link } from 'react-router-dom';

class PortalPage extends Component {

    constructor(props, context) {
        super(props, context);

        this.submitSignin = this.submitSignin.bind(this);
    }
    
    componentDidMount() {
        const token = localStorage.getItem('token');
        if (token) { 
            this.props.tokenSignin(token, this.props.history);
        } else if (!this.props.school._id) {
            this.props.getSchool(this.props.schoolURL);
        }
    }

    submitSignin = (values) => {
       this.props.signinUser(values, this.props.history);
   }

    render() {

        const { school } = this.props;

        if (!school.name) {
            return (<div>Loading...</div>);
        }

        return (
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="navigation-style clearfix" style={{'backgroundColor': school.schoolColour}}>
                            <a className={(school.hasLogo) ? 'nav-logo pull-left' : 'nav-logo pull-left no-logo'} href="#">{(school.hasLogo) && <img src={`https://portal.msmusic.com.au/${school._id}.png`} alt="logo"/>} {(!school.hideName) && school.name}</a>
                        </div>
                    </div>
                </div>
                <div className="padding-top-two">
                    <div className="col-sm-7 pt-tree-node-content-2">
                        <div className="card-block pt-card pt-elevation-2">
                            <div dangerouslySetInnerHTML={{__html: school.portalText}} />
                        </div>
                    </div>
                    <div className="col-sm-4">
                    
                        <div className="panel panel-default">
                            <div className="panel-heading">Portal Sign In</div>
                            <div className="panel-body">
                                <div className="col-sm-12 text-center">
                                    <SignInForm onSubmit={this.submitSignin} />
                                </div>
                                
                            </div>
                            <div className="panel-footer">
                                <Link to="password">Forgot Password?</Link>
                            </div>

                        </div>
                        <br /> 
                        { school._id && school.newRegActive &&<Link to="register" className="btn btn-success btn-block">New Student Registration</Link> }
                    </div>
                </div>
            </div> 
        );
    }
}

PortalPage.propTypes = {
  school: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  schoolURL: PropTypes.string,
  getSchool: PropTypes.func.isRequired,
  signinUser: PropTypes.func.isRequired,
  tokenSignin: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    school: state.portal.school,
    schoolURL: ownProps.match.params.id
  };
}

export default connect(mapStateToProps, actions)(PortalPage);

