import React from "react";
import { reduxForm, propTypes } from "redux-form";

const EnrolmentWithdraw = props => {
  const { handleSubmit, submitting, closeModal, previousPage, enrolment, student } = props;
  return (
    <div>
      <div className="row text-center">
        <h4>{enrolment.reEnrolmentTitle}</h4>
      </div>
      <div className="clearfix basic-form">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="row">
              <div className="col-sm-12 text-center">
                  Please confirm that <span className="blueColor">{`${student.firstName} ${student.lastName}`}</span> will not be continuing in the music program next year.
              </div>
            </div>
          </div>
          <br />
          <hr />
          <div className="row">
              <div className="col-xs-6">
                  <button type="button" className="btn btn-primary" onClick={previousPage}>Previous</button>
              </div>
              <div className="col-xs-6 text-right">
                {closeModal && <button type="button" className="btn btn-default" onClick={closeModal}>Close</button>}&nbsp;&nbsp;
                <button type="submit" className="btn btn-success" disabled={submitting}>Confirm</button>
              </div>
            </div>
        </form>
      </div>
    </div>
  );
};

EnrolmentWithdraw.propTypes = {
  ...propTypes
};

export default reduxForm({
  form: "enrolment",
  destroyOnUnmount: false
})(EnrolmentWithdraw);
