import React from 'react';

const Footer = () => {
    return(
        <footer>
        <div className="copyrights">
            © Copyrights 2017 <a href="http://www.myschoolmusic.com.au/" target="_blank">My School Music</a> All Rights Reserved.
        </div>
    </footer>
    );
};

export default Footer;

