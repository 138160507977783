import { GET_FORM, GET_EVENTS, GET_DOCUMENTS, GET_SCHOOL, UPDATE_SCHOOL, 
    GET_GRADES, SET_INVOICE, SET_FORM_OPTIONS, TOGGLE_WAIT, GET_NEW_FORM, GET_ENROL_FORM,
    GET_CUSTOM_FORM } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.portal, action) {
    switch(action.type) {

        case GET_FORM:
             return { ...state, forms: action.payload };
        
        case GET_NEW_FORM:
             return { ...state, forms: { ...state.forms, newReg: action.payload } };

        case GET_ENROL_FORM:
            return { ...state, forms: { ...state.forms, reEnrolment: action.payload } };

        case SET_FORM_OPTIONS:
             return { ...state, forms: { ...state.forms, options: action.payload } };

        case GET_CUSTOM_FORM:
            return { ...state, forms: { ...state.forms, customForm: action.payload } };

        case GET_EVENTS:
             return { ...state, events: action.payload };

        case GET_DOCUMENTS:
             return { ...state, documents: action.payload };

        case GET_SCHOOL:
             return { ...state, school: action.payload };

        case UPDATE_SCHOOL:
             return { ...state, school: action.payload };

        case GET_GRADES:
             return { ...state, school: { ...state.school, selectors: { grades: action.payload } } };

        case SET_INVOICE:
             return { ...state, studentInv: action.payload.studentInv, schoolInv: action.payload.schoolInv  };

        case TOGGLE_WAIT:
            return { ...state, wait: !state.wait };

        default:
            return state;
    }
}