import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';


const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
    <div>
        <DropdownList {...input} {...rest} onBlur={() => input.change} />
        { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
);

const renderTextArea = ({ input }) => <textarea className="form-control" {...input} />;


const renderGroups = ({ fields, options }) => {
    if (!fields.length) fields.push({});

    return (
    <div>
        <div>
            <button type="button" className="btn btn-default btn-xs rounded pull-right" onClick={() => fields.push({})}>Add an Ensemble</button>
        </div>
        <div className="padding-top-one">
            <table className="table table-bordered margin-top-one">
            <thead>
            <tr>
                <th width="50%">Group</th>
                <th width="50%">Instrument</th>
                <th width="10px"><i className="fa fa-trash" aria-hidden="true"></i></th>
            </tr>
            </thead>
            <tbody>
                {fields.map((groups, index) => (
                    <tr key={index}>
                        <td><Field name={`${groups}.group`} type="text" valueKey="_id" labelKey="name" clearable={false} component={renderDropdownList} options={options.groups} /></td>
                        <td><Field name={`${groups}.instrument`} type="text" valueKey="label" simpleValue={true} clearable={false} component={renderDropdownList} options={options.instruments} /></td>
                        <td>{(index) ? <div className="top-5" onClick={() => fields.remove(index)}><i className="fa fa-times">&nbsp;</i></div> : null}</td>
                    </tr>
                )
            )}
            </tbody>
            </table>
        </div>
        
    </div>
    );
};

const EnrolmentForm = (props) => {
    const { handleSubmit, submitting, enrolmentForm, closeModal } = props;

    return (
        <div className="basic-form">
            <form onSubmit={handleSubmit}>
                <div dangerouslySetInnerHTML={{ __html: enrolmentForm.enrolment.enrolmentText}} />
                <hr />
                <div className="row">
                    <div className="form-group">
                        <div className="col-md-12">
                            <FieldArray name="groups" component={renderGroups} options={enrolmentForm.options} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group">
                        <div className="col-md-12"><strong>Comment</strong></div>
                        <div className="col-md-12 padding-top-one">
                            <Field name="comment" component={renderTextArea} />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <button type="submit" disabled={submitting} className="btn btn-primary rounded">Submit</button>&nbsp;&nbsp;
                        <button type="button" className="btn btn-default rounded" onClick={() => closeModal()}>Close</button>
                     </div>
                </div>
        </form>
    </div>
    );
};

EnrolmentForm.propTypes = {
    ...propTypes,
};

renderGroups.propTypes = {
    fields: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired
};

renderTextArea.propTypes = {
    input: PropTypes.object.isRequired
};

renderDropdownList.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'enrolmentForm'
})(EnrolmentForm);