import React from 'react';
import PropTypes from 'prop-types';

const renderGroupMeetings = meetings => {
    return meetings.map((meeting, index) => {
        return (
            <div key={index}>{meeting}</div>
        );
    });
};

const renderGroupContacts = contacts => {
    return contacts.map((contact, index) => {
        return (
            <div key={index}>{`${contact.name} `}{(contact.role) ? `(${contact.role})` : ''}</div>
        );
    });
};

const renderContacts = contacts => (
    <div>
        <div className="col-md-3">Contacts:</div>    
        <div className="col-md-9">{renderGroupContacts(contacts)}</div>
    </div>
    
);

const renderMeetings = meetings => (
    <div>
        <div className="col-md-3">Rehearsals:</div>    
        <div className="col-md-9">{renderGroupMeetings(meetings)}</div>
    </div>
    
);


const renderGroups = groups => {
    return groups.map((group, index) => {
        return (
            <li key={index}>
                <div><strong>{group.name}</strong></div>
                {(group.contacts.length > 0) && renderContacts(group.contacts)}
                {(group.meetings.length > 0) && renderMeetings(group.meetings)}
           </li>
        );
    });
};

const renderEnrolment = enrolments => {
    return enrolments.map((group, index) => {
        return (
            <li key={index}>
                <div><strong>{group.name}</strong></div>
                <div>
                    <div className="col-md-3">Status:</div>    
                    <div className="col-md-9">New Enrolment Request</div>
                </div>
                <div>
                    <div className="col-md-3">Instrument:</div>    
                    <div className="col-md-9">{group.instrument}</div>
                </div>
           </li>
        );
    });
};

const renderNoGroups = () => <li key={'new'}><strong>Status: </strong>New student application being processed</li>;

const GroupDetails = (props) => {

  const { groups, enrolments } = props;
    return (
        <ul className="list-unstyled">
            {(groups.length) ? renderGroups(groups) : renderNoGroups()}
            {(enrolments) && renderEnrolment(enrolments)}
        </ul>
    );
};

GroupDetails.propTypes = {
    groups: PropTypes.array.isRequired,
    enrolments: PropTypes.array
};

export default GroupDetails;