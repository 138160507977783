//require('../common/validation');
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required, email } from '../common/validation';
import { Checkbox } from "@blueprintjs/core";
  
// const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
 
 const renderCheckbox = ({ input }) => 
    <label className="pt-control pt-checkbox pt-large text-left">
        <Checkbox checked={input.value} onChange={input.onChange} label="&nbsp;&nbsp;Remember me?" /> 
    </label>; 
 
 const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
      <input className="form-control" {...input} placeholder={label} type={type}/>
        { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);
 
 const SigninForm = (props) => {
    
    const { handleSubmit, submitting } = props;

    return (
        <div className="col-sm-12">
            <form onSubmit={handleSubmit} className="form-horizontal">
                <div className="form-group">
                    <Field name="email" type="email" component={renderField} label="Email" validate={email} />
                </div>
                <div className="form-group">
                    <Field name="password" type="password" component={renderField} label="Password" validate={required} />
                </div>
                <div className="form-group">
                    <div className="checkbox">
                        <Field name="remember" id="remember" component={renderCheckbox} />

                       
                    </div>
                    
                    <div className="submit-form margin-top-two">
                        <button type="submit" className="submit-btn" disabled={submitting}>Sign In</button>
                    </div>
                </div>    
            </form>
        </div>
    );
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired
};

renderCheckbox.propTypes = {
    input: PropTypes.object.isRequired
};

SigninForm.propTypes = {
  ...propTypes
};

export default reduxForm({
    form: 'signinForm'
})(SigninForm);