import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required } from '../../common/validation';
import { Checkbox } from "@blueprintjs/core";

const renderCheckbox = ({ input }) => 
    <label className="pt-control pt-checkbox pt-large">
        <Checkbox checked={input.value} onChange={input.onChange} /> 
    </label>;
  
  
 const ParentAgreement = (props) => {
    
    const { handleSubmit, agreement, invalid } = props;

    return (
        <form onSubmit={handleSubmit} className="form-horizontal">
            <div className="row">
                <div className="col-sm-12">
                    <div dangerouslySetInnerHTML={{__html: agreement.parentAgreement}} />
                </div>
            </div>
            <br/><br/>
            <div className="form-group">
                <div className="col-xs-1 col-xs-offset-1">
                    <Field name="ParentAgreement" component={renderCheckbox} validate={required} />
                </div>
                <div className="col-xs-10">{agreement.acceptAgreementText}</div>
            </div>

            <hr />
            <div className="text-right">
                <button type="submit" className="btn btn-primary" disabled={invalid}>Next</button>
            </div>
        </form>
    );
};


renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired
};

ParentAgreement.propTypes = {
  ...propTypes
};

export default reduxForm({
    form: 'newRegistration',
    destroyOnUnmount: false, 
    forceUnregisterOnUnmount: true,
    enableReinitialize: true
})(ParentAgreement);