import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import PasswordForm from '../contact/password-form';
import { Link } from 'react-router-dom';

class UpdatePasswordPage extends Component {

    constructor(props, context) {
        super(props, context);

        this.updatePassword = this.updatePassword.bind(this);

  }

  componentWillMount() {
        this.props.checkPwdtoken(this.props.match.params.token);
    }    

    updatePassword = (password) => {
        this.props.savePassword(password, this.props.match.params.token, this.props.history);
    }

  render() {
      return(
            <div>
            <div className="user-access-page">
                <div className="row">
                    <div className="col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6">
                        <div className="login-widget">
                            <div className="login-box">
                                <h3 className="blueBG">Enter a New Password</h3>
                                    {(this.props.passwordToken) ? <PasswordForm onSubmit={this.updatePassword} /> : <h4>Password reset link has expired</h4>}
                                <div className="login-footer">
                                    <Link className="grey-text small" to="/signin">Sigin</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="copyrights">© Copyrights 2016 <a href="http://www.myschoolmusic.com.au/" target="_blank">My School Music</a> All Rights Reserved.
                </div>
            </footer>
        </div>
      );
  }
}

UpdatePasswordPage.propTypes = {
  passwordToken: PropTypes.bool,
  savePassword: PropTypes.func.isRequired,
  checkPwdtoken: PropTypes.func.isRequired
};

function mapStateToProps(state) {
   return {
    passwordToken: state.contact.resetPassword
    };
    
}

export default connect(mapStateToProps, actions)(UpdatePasswordPage);

