import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from "redux-form";
import DropdownList from 'react-select';
import { required } from '../common/validation';

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
      <DropdownList {...input} {...rest} onBlur={() => input.change} />
      { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
  </div>
);


const EnrolmentInfo = props => {
  const { handleSubmit, closeModal, enrolment, student } = props;
  return (
    <div className="padding-modal">
      <div className="row text-center">
        <h4>{enrolment.reEnrolmentTitle}</h4>
        <h5 className="blueColor">{`${student.firstName} ${student.lastName}`}</h5>
      </div>
      <div className="clearfix basic-form">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="row">
              <div className="col-sm-12">
                <div dangerouslySetInnerHTML={{ __html: enrolment.reEnrolmentInfo}} />
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-10 padding-top-half">
                <strong>{enrolment.reEnrolmentText}</strong>
              </div>
              <div className="col-xs-2">
                <Field name="reenrole" type="text" component={renderDropdownList} simpleValue={true} options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]} clearable={false} validate={[required]} />
              </div>
            </div>
          </div>
          <hr />
          <div className="margin-top-one">
            <div className="col-xs-6">
              <button type="button" className="btn btn-sm btn-default rounded" onClick={() => closeModal()}>Close</button>
            </div>
            <div className="col-xs-6 text-right">
              <button type="submit" className="btn btn-primary rounded">Next >></button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

EnrolmentInfo.propTypes = {
  ...propTypes
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default reduxForm({
  form: "enrolment",
  destroyOnUnmount: false
})(EnrolmentInfo);
