import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required, email, number } from '../../common/validation';
import { Checkbox } from "@blueprintjs/core";

const renderCheckbox = ({ input, label }) => 
    <label className="pt-control pt-checkbox pt-large">
        <Checkbox checked={input.value} onChange={input.onChange} label={label} /> 
    </label>;
  
 const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div className={(touched && error) ? 'has-error' : ''}>
    <label className="col-sm-3 control-label">{label}</label>
    <div className="col-sm-8">
         <input className="form-control" {...input} type={type}/>
            { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
  </div>
  );
 
 const ParentDetails = (props) => {
    
    const { handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit} className="form-horizontal">
            <h5 className="blueColor text-center">Parent Details - First Contact</h5>
            <div className="form-group">
                <Field name="contact.contact1.firstName" type="text" component={renderField} label="First Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="contact.contact1.lastName" type="text" component={renderField} label="Last Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="contact.contact1.email" type="email" component={renderField} label="Email" validate={[required, email]} />
            </div>
            <div className="form-group">
                <Field name="contact.contact1.mobile" type="text" component={renderField} label="Mobile" validate={[required, number]} />
            </div>
            <div className="form-group">
                <Field name="contact.contact1.address" type="text" component={renderField} label="Address" />
            </div>
            <hr />
            <h5 className="blueColor text-center">Second Contact (Optional)</h5>
            <div className="form-group">
                <Field name="contact.contact2.firstName" type="text" component={renderField} label="First Name" />
            </div>
            <div className="form-group">
                <Field name="contact.contact2.lastName" type="text" component={renderField} label="Last Name" />
            </div>
            <div className="form-group">
                <Field name="contact.contact2.email" type="email" component={renderField} label="Email" validate={[email]} />
            </div>
            <div className="form-group">
                <Field name="contact.contact2.mobile" type="text" component={renderField} label="Mobile" validate={[number]} />
            </div>
            <div className="form-group">
                <Field name="contact.contact2.address" type="text" component={renderField} label="Address" />
            </div>
            <div className="form-group">
                <div className="col-sm-8 col-sm-offset-3">
                    <Field name="contact.contact2.sendEmail" component={renderCheckbox} label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Send emails to both first and second contacts" />
                </div>
            </div>

            <hr />
            <div className="form-group">
                <Field name="contact.password" type="password" component={renderField} label="Password" validate={[required]} />
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-12 text-right">
                  <button type="submit" className="btn btn-primary">Next</button>
              </div>
            </div>
        </form>
    );
};


renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

ParentDetails.propTypes = {
  ...propTypes
};

export default reduxForm({
    form: 'newRegistration',
    destroyOnUnmount: false, 
    forceUnregisterOnUnmount: true
})(ParentDetails);