import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
 
const CalEvent = (props) => {
    
    const { event } = props;

    return (
        <div>
            
            <ul className="list-unstyled user-contact-detail">
                <li><strong>Event: </strong>&nbsp;&nbsp;&nbsp;{event.eventTitle}</li>
                <li><strong>Start: </strong>&nbsp;&nbsp;&nbsp;{moment(event.eventStart).format('ddd, D MMM YY @ h:mm a')}</li>
                <li><strong>End: </strong>&nbsp;&nbsp;&nbsp;{moment(event.eventEnd).format('ddd, D MMM YY @ h:mm a')}</li>
                <li><strong>Venue: </strong>&nbsp;&nbsp;&nbsp;{event.eventVenue}</li>
                <li><strong>Comment: </strong><br />{event.eventDescription}</li>
            </ul>
        </div>
    );
};

CalEvent.propTypes = {
    event: PropTypes.object.isRequired
};

export default CalEvent;