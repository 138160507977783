export default {
    notifications: {},
    selectedStudent: {},
    contact: { 
        contact1: {},
        contact2: {}, 
        emergency: {},
        students: [],
        enrolments: [],
        customForms: [],
        signin: false,
     },
     portal: {
        school: {},
        events: [],
        documents: [],
        forms: { enrolment: {}, newReg: {}, customForm: {} }
     }
};