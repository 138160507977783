import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const renderInvoices = (invoices, openModal) => { 
    return invoices.map((invoice, index) => {
        const paid = invoice.payments.reduce(( total, payment ) => total + payment.amount, 0);
        return (
          <tr key={index} onClick={() => openModal('Invoice', invoice)}>
            <td>{moment(invoice.invoiceDate).format('D MMM YY')}</td>
            <td>{invoice.invoiceTo}</td>
            <td>${invoice.amount.toFixed(2)}</td>
            <td>{(paid > 0) && `$${paid.toFixed(2)}`}</td>
        </tr>
       );
    });
};

const renderNoInvoices = () => (
      <tr key="1">
          <td colSpan="4">You currently have no invoices</td>
      </tr>
);

const AccountsModule = (props) => {

  const { invoices, openModal } = props;

    return (
        <div className="panel panel-warning table-panel">
          <div className="panel-heading">Invoices</div>
            <div className="panel-body">
                <div className="table-content-box">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th width="85">Date</th>
                            <th>Invoice For</th>
                            <th width="85">Amount</th>
                            <th width="85">Paid</th>
                        </tr>
                        </thead>
                        <tbody>
                          {(invoices && invoices.length > 0) ? renderInvoices(invoices, openModal) : renderNoInvoices()}
                        </tbody>
                    </table>
                    <hr />
                    <div className="text-center small"><strong>Note: </strong>Some schools do not record payments through MS Music</div>
                </div>
            </div>
        </div>
    );
};

AccountsModule.propTypes = {
    invoices: PropTypes.array,
    openModal: PropTypes.func.isRequired
};

export default AccountsModule;