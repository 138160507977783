import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/forms';
import CustomForm from './custom-form';

class CustomFormModal extends Component {
  componentWillMount() {
    console.log(this.props);
    this.props.getCustomForm(this.props.studentForm.id, this.props.student.id);
  }

  saveCustomForm = (values) => {
    console.log(this.props.formDetails);
    console.log(values);
    const formAnswers = values.questions.reduce((answers, item) => {
      if (item.type !== 'Heading') {
        answers.push({ response: item.answer, comment: item.comment, questionText: item.questionText });
      }
      return answers;
    }, []);
    this.props.saveForm(formAnswers);
  };

  render() {
    const { onCancel, formDetails } = this.props;

    if (!formDetails) {
      return <div>Loading...</div>;
    }

    // Add questions to redux form as hidden values
    // const initialValues = { questions: {} };
    //   formDetails.questions.map(question => {
    //  initialValues.questions[question._id] = { question: question.questionText };
    //  });

    return (
      <div>
        <CustomForm onSubmit={this.saveCustomForm} initialValues={formDetails} onCancel={onCancel} />
      </div>
    );
  }
}

CustomFormModal.propTypes = {
  getCustomForm: PropTypes.func.isRequired,
  saveForm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  formDetails: PropTypes.object,
  studentForm: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    formDetails: state.portal.forms.customForm,
  };
}

export default connect(mapStateToProps, actions)(CustomFormModal);
