import React, { Component } from 'react';
import { Tab, Nav, NavItem, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/forms';
import ContactForm from './contact-details-form';

class ContactDetailsPage extends Component {

    constructor(props, context) {
        super(props, context);
    }

    updateContact = (contact) => {
        this.props.updateStudentContact(contact);
    }

    render() {

        const { contact, closeModal, changePassword } = this.props;

        return (
          <div>
                 
             <Tab.Container id="contact-tabs" defaultActiveKey="contact1">
                <div>
                <Nav bsStyle="tabs">
                    <NavItem eventKey="contact1">
                        First Contact
                    </NavItem>
                    <NavItem eventKey="contact2">
                        Second Contact
                    </NavItem>
                </Nav>       
                <Tab.Content animation>               
                    <Tab.Pane eventKey="contact1">
                        <div className="form-padding">
                            <ContactForm 
                                initialValues={contact} 
                                onSubmit={this.updateContact} 
                                closeModal={closeModal}
                                contact1
                                changePassword={changePassword} />
                        </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="contact2">
                        <div className="form-padding">
                            <ContactForm 
                                initialValues={contact} 
                                onSubmit={this.updateContact} 
                                closeModal={closeModal}
                                changePassword={changePassword} />
                        </div>
                    </Tab.Pane>
                </Tab.Content>   
                </div> 
            </Tab.Container>
          </div>
        );
    }
}

ContactDetailsPage.propTypes = {
  contact: PropTypes.object.isRequired,
  updateStudentContact: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        contact: state.contact
    };
}

export default connect(mapStateToProps, actions)(ContactDetailsPage);