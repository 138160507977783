import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import * as actions from '../../actions';
import Invoice from '../auth/invoice/invoice';

class InvoiceModal extends Component {
  
  downloadInvoice = (id, invNumber) => {
    this.props.getInvoicePdf(id, invNumber);
}

    render() {
        const { schoolInv, studentInv, closeModal, wait } = this.props;

        if (!studentInv) {
            return (<div>Loading...</div>);
        }

        const schoolInvoice = schoolInv.find(school => school.schoolId === studentInv.schoolId);

        return (
            <div>
               <br />
               <Invoice ref={el => (this.componentRef = el)} schoolInv={schoolInvoice} studentInv={studentInv} />
               <hr />
               <div>
                  <button type="button" className="btn btn-primary rounded" disabled={wait} onClick={() => this.downloadInvoice(studentInv._id, studentInv.invoiceNumber)}>{(wait) ? 'Downloading...' : 'Download'}</button>&nbsp;&nbsp;
                  <ReactToPrint
                        trigger={() => <button type="button" className="btn btn-info rounded">Print</button>}
                        content={() => this.componentRef}
                    />&nbsp;&nbsp;
                  <button type="button" className="btn btn-default rounded" onClick={() => closeModal()}>Close</button>
               </div>
               
            </div> 
        );
    }
}

InvoiceModal.propTypes = {
    schoolInv: PropTypes.array.isRequired,
    studentInv: PropTypes.object.isRequired,
    wait: PropTypes.bool,
    getInvoicePdf: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
};

export default connect(null, actions)(InvoiceModal);

