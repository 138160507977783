import React from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer  } from 'react-big-calendar'
import moment from 'moment';
import '../../styles/css/react-big-calendar.css';

const localizer = momentLocalizer(moment)

const EventCal = (props) => {

    const { events, openEvent } = props;

    return (
            <div className="bigCal veiwCal">
                <Calendar
                    localizer={localizer}
                    selectable
                    startAccessor="eventStart"
                    endAccessor="eventEnd"
                    titleAccessor="eventTitle"
                    events={events}
                    min={new Date(2018, 10, 19, 6, 0, 0)}
                    max={new Date(2018, 10, 19, 21, 0, 0)}
                    defaultView="month"
                    views={['month', 'week']}
                    onSelectEvent={event => openEvent(event)}
                    eventPropGetter={(event) => ({style: { backgroundColor: event.eventColour }})}
                />
            </div>
    );
};

EventCal.propTypes = {
    events: PropTypes.array.isRequired,
    openEvent: PropTypes.func.isRequired
};

export default EventCal;