import axios from "axios";
import Notifications from "react-notification-system-redux";
import { reset } from "redux-form";
import {
  ROOT_URL,
  GET_NEW_FORM,
  GET_ENROL_FORM,
  ADD_STUDENT,
  SET_STUDENT,
  REMOVE_ENROLMENT,
  GET_GRADES,
  SIGN_IN,
  GET_ENROLMENTS,
  SET_FORM_OPTIONS,
  GET_STUDENT_INFO,
  REMOVE_CUSTOM_FORM,
  GET_CUSTOM_FORM,
  UPDATE_CONTACT,
} from "./types";

// Get New Student Regsistration Form
export function getNewStudentForm(id) {
  return function (dispatch) {
    axios
      .get(`${ROOT_URL}/portal/new-student-form/${id}`)
      .then((response) => {
        dispatch({ type: GET_NEW_FORM, payload: response.data.regForm });
        dispatch({ type: GET_GRADES, payload: response.data.grades });
      })
      .catch(() => {
        dispatch(Notifications.error({ title: "Could find registration form", message: "Check your registration url" }));
      });
  };
}

// Get New Student Regsistration Form
export function getEnrolmentForm(id) {
  return function (dispatch) {
    axios
      .get(`${ROOT_URL}/portal/enrolment-form/${id}`)
      .then((response) => {
        dispatch({ type: GET_ENROL_FORM, payload: response.data });
      })
      .catch(() => {
        dispatch(Notifications.error({ title: "Could not find registration form" }));
      });
  };
}

// Save new student registration details
export function singleNewRegistration(student, schoolId) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/portal/single-page-registration/${schoolId}`, student)
      .then(() => {
        dispatch(
          Notifications.success({
            autoDismiss: 15,
            title: "Your registration has been saved",
            message: `A confirmation email has been sent to ${student.contact.email}`,
          })
        );
        dispatch(reset("SingleNewStudentsForm"));
      })
      .catch((err) => {
        dispatch(Notifications.error({ title: "Could Not Save Registration", message: err.response.data }));
      });
  };
}

// Save new student registration details
export function newStudentRegistration(student, schoolId, history) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/portal/new-student-registration/${schoolId}`, student)
      .then((response) => {
        dispatch(
          Notifications.success({
            autoDismiss: 12,
            title: "Welcome to the Portal",
            message: `A confirmation email has been sent to ${student.contact.contact1.email}`,
          })
        );
        dispatch(reset("newRegistration"));
        localStorage.setItem("token", response.data);

        return axios.get(`${ROOT_URL}/portal/signin`, {
          headers: { authorization: response.data },
        });
      })
      .then((response) => {
        response.data.sContact.signin = true;
        response.data.sContact.remember = true;
        dispatch({ type: SIGN_IN, payload: response.data.sContact });
        dispatch({ type: SET_STUDENT, payload: response.data.sContact.students[0] });

        history.push("/dashboard");
      })
      .catch((err) => {
        dispatch(Notifications.error({ title: "Could Not Save Registration", message: err.response.data }));
      });
  };
}

export function getEnrolmentOptions(id) {
  return function (dispatch) {
    axios
      .get(`${ROOT_URL}/portal/enrolment-options/${id}`, {
        headers: { authorization: localStorage.getItem("token") },
      })
      .then((response) => {
        dispatch({ type: SET_FORM_OPTIONS, payload: response.data });
      })
      .catch((err) => {
        throw err;
      });
  };
}

// Save student contact details
export function updateStudentContact(values) {
  return function (dispatch) {
    const contact = { contact1: values.contact1, contact2: values.contact2, emergency: values.emergency };
    axios
      .post(`${ROOT_URL}/portal/contact-details/${values._id}`, contact, { headers: { authorization: localStorage.getItem("token") } })
      .then(() => {
        dispatch({ type: UPDATE_CONTACT, payload: { ...contact, updated: true } });
        dispatch(Notifications.success({ title: "Contact Details Saved" }));
      })
      .catch(() => {
        dispatch(Notifications.error({ title: "Could Not Save Contact Details" }));
      });
  };
}

// Save new student registration details
export function addStudent(student) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/portal/add-student`, student, { headers: { authorization: localStorage.getItem("token") } })
      .then((response) => {
        dispatch(Notifications.success({ title: "Your registration has been saved" }));
        dispatch({ type: ADD_STUDENT, payload: response.data });
        dispatch({ type: SET_STUDENT, payload: response.data });
        dispatch(reset("StudentRegistrationForm"));
      })
      .catch((err) => {
        dispatch(Notifications.error({ title: "Could Not Save Registration", message: err.response.data }));
      });
  };
}

export function saveReEnrolment(enrolment, student, index) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/portal/re-enrolments/${student._id}`, enrolment, { headers: { authorization: localStorage.getItem("token") } })
      .then(() => {
        dispatch({ type: REMOVE_ENROLMENT, payload: index });
        dispatch(Notifications.success({ title: `Enrolment for ${student.firstName} Saved` }));
        dispatch(reset("enrolment"));
      })
      .catch(() => {
        dispatch(Notifications.error({ title: "Could Not Save Enrolment Details" }));
      });
  };
}

// Save student contact details
export function saveEnrolment(enrolment, id) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/portal/enrolment/${id}`, enrolment, { headers: { authorization: localStorage.getItem("token") } })
      .then((response) => {
        dispatch(Notifications.success({ title: "New Enrolment Request Saved" }));
        dispatch({ type: GET_ENROLMENTS, enrolments: response.data.enrolment.groups });
        dispatch(reset("enrolmentForm"));
      })
      .catch(() => {
        dispatch(Notifications.error({ title: "Could Not Save Enrolment Request" }));
      });
  };
}

export function getStudentInfo(studentId) {
  return function (dispatch) {
    axios
      .get(`${ROOT_URL}/portal/student-info/${studentId}`, {
        headers: { authorization: localStorage.getItem("token") },
      })
      .then((response) => {
        dispatch({ type: GET_STUDENT_INFO, payload: response.data });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function saveStudentInfo(student, studentId) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/portal/student-info/${studentId}`, student, { headers: { authorization: localStorage.getItem("token") } })
      .then((response) => {
        dispatch(Notifications.success({ title: "Student Details Updated" }));
        dispatch({ type: GET_STUDENT_INFO, payload: response.data });
      })
      .catch(() => {
        dispatch(Notifications.error({ title: "Could Not Update Student Details" }));
      });
  };
}

export function getCustomForm(formId, studentId) {
  return function (dispatch) {
    dispatch({ type: GET_CUSTOM_FORM, payload: undefined });
    axios
      .get(`${ROOT_URL}/portal/custom-form/${formId}/${studentId}`, {
        headers: { authorization: localStorage.getItem("token") },
      })
      .then((response) => {
        dispatch({ type: GET_CUSTOM_FORM, payload: response.data });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function saveCustomForm(formAnswers, formId, studentId, studentIndex, student) {
  return function (dispatch) {
    axios
      .post(`${ROOT_URL}/portal/custom-forms/${formId}/${studentId}`, formAnswers, {
        headers: { authorization: localStorage.getItem("token") },
      })
      .then(() => {
        dispatch(Notifications.success({ title: "Form Saved" }));
        dispatch({ type: REMOVE_CUSTOM_FORM, studentIndex, student });
        dispatch(reset("CustomForm"));
      })
      .catch(() => {
        dispatch(Notifications.error({ title: "We were not able to save your form" }));
      });
  };
}
