import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { DateInput } from '@blueprintjs/datetime';
import moment from 'moment';
import { required } from '../common/validation';

 const renderField = ({ input, label, placeholder, type, meta: { touched, error } }) => (
  <div className={(touched && error) ? 'has-error' : ''}>
    <label className="col-sm-3 control-label">{label}</label>
    <div className="col-sm-8">
         <input className="form-control" {...input} type={type} placeholder={placeholder}/>
            { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
  </div>
  );

  const renderDatePicker = ({ input, label, meta: { touched, error } }) => (
    <div>
        <label className="col-sm-3 control-label">{label}</label>
        <div className="col-sm-8">
            <DateInput
            value={input.value}
            onChange={input.onChange}
            format={'DD MMM YYYY'}
            minDate={moment().add(-18, 'years').toDate()}
            maxDate={moment().add(-4, 'years').toDate()}
            />
            {touched && error && (
            <div className='admin-label text-right margin-top-quarter'>
                <i className='redBG'>{error}</i>
            </div>
            )}
        </div>
    </div>
    );

  const renderDropdownList = ({ input, label, meta: { touched, error }, ...rest }) => (
    <div>
        <label className="col-sm-3 control-label">{label}</label>
        <div className="col-sm-8 has-error">
            <DropdownList {...input} {...rest} onBlur={() => input.change} />
            { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
        </div>
    </div>
    );
 
 const UpdateStudentDetails = (props) => {
    
    const { handleSubmit, grades } = props;

    return (
        <form onSubmit={handleSubmit} className="form-horizontal">
            <h5 className="blueColor text-center">Student Details</h5>
            <div className="form-group">
                <Field name="firstName" type="text" component={renderField} label="First Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="lastName" type="text" component={renderField} label="Last Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="grade" type="text" clearable={false} component={renderDropdownList} options={grades} valueKey="label" simpleValue={true} validate={required} label="Grade" />   
            </div>
            <div className="form-group">
                <Field name="class" type="text" component={renderField} label="Class" />  
            </div>

            <div className="form-group">
                <Field name="privateTutor" type="text" component={renderField} label="Tutor" />  
            </div>

            <div className="form-group">
                <Field name='DOB' label="Date of Birth" type='text' component={renderDatePicker}  />
            </div>

            <div className="form-group">
                <Field name="voucher" type="text" component={renderField} label="Voucher" placeholder="Creative Kids or other voucher" />  
            </div>

            <div className="form-group">
                <Field name="mediCareName" type="text" component={renderField} label=" Medicare Name" placeholder="Student name on Medicare card" />  
            </div>

            <hr />
            <div className="row">
              <div className="col-md-12 text-right">
                  <button type="submit" className="btn btn-primary">Save</button>
              </div>
            </div>
        </form>
    );
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.func
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired
};

UpdateStudentDetails.propTypes = {
  ...propTypes
};

export default reduxForm({
    form: 'UpdateStudentDetails'
})(UpdateStudentDetails);