import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { required, email, number, minLength5 } from '../common/validation';

const renderField = ({ input, label, type, readOnly, meta: { touched, error } }) => (
  <div className={(touched && error) ? 'has-error' : ''}>
    <label className="col-xs-3 col-lg-4 control-label">{label}</label>
    <div className="col-xs-8 col-lg-6">
         <input className="form-control" readOnly={readOnly} {...input} type={type}/>
            { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
  </div>
  );

 const RegistrationForm = (props) => {
    
    const { handleSubmit, submitting } = props;

    return (
        <form onSubmit={handleSubmit} className="form-horizontal">
            <h4 className="blueColor text-center">Main contact</h4>
            <div className="form-group">
                <Field name="contact1.firstName" type="text" component={renderField} label="First Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="contact1.lastName" type="text" component={renderField} label="last Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="contact1.email" type="email" component={renderField} label="Email" validate={[required, email]} />
            </div>
            <div className="form-group">
                <Field name="contact1.mobile" type="text" component={renderField} label="Mobile" validate={[required, number]} />
            </div>
            <div className="form-group">
                <Field name="contact1.address" type="text" label="Address" component={renderField} />
            </div>
             
            <hr />
            <h4 className="blueColor text-center">Second contact</h4>
            <div className="form-group">
                <Field name="contact2.firstName" type="text" label="First Name" component={renderField} />
            </div>
            <div className="form-group">
                <Field name="contact2.lastName" type="text" label="Last Name" component={renderField} />
            </div>
            <div className="form-group">
                <Field name="contact2.email" type="email" label="Email" component={renderField} validate={email} />
            </div>
            <div className="form-group">
                <Field name="contact2.mobile" type="text" label="Mobile" component={renderField} validate={number} />
            </div>
            <div className="form-group">
                <Field name="contact2.address" type="text" label="Address" component={renderField} />
            </div>

            <hr />
            <div className="form-group">
                <Field name="password" type="password" label="Password" component={renderField} validate={[required, minLength5]} />
            </div>

            <div className="form-actions clearfix">
                <div className="submit-form pull-right">
                    <button type="submit" className="submit-btn" disabled={submitting}>Save</button>
                </div>
           </div>    
        </form>
    );
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  readOnly: PropTypes.bool
};

RegistrationForm.propTypes = {
  ...propTypes
};

export default reduxForm({
    form: 'registrationForm'
})(RegistrationForm);