import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { DateInput } from '@blueprintjs/datetime';
import DropdownList from 'react-select';
import { Checkbox } from 'react-icheck';
import moment from 'moment';

const relationship = [{ label: 'Father' }, { label: 'Mother' }, { label: 'Grandparent' }, { label: 'Gardian' }, { label: 'Other' }];

const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div>
    <DropdownList {...input} {...rest} onBlur={() => input.change} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderCheckbox = ({ input }) => (
  <Checkbox
    checkboxClass='icheckbox_square-blue'
    increaseArea='20%'
    onChange={input.onChange}
    defaultChecked={input.value ? true : false}
  />
);

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <input className='form-control' {...input} type={type} placeholder={label} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderDatePicker = ({ input, max, min, meta: { touched, error } }) => (
  <div>
    <DateInput
      value={input.value}
      onChange={input.onChange}
      format={'DD MMM YYYY'}
      minDate={moment().add(min, 'years').toDate()}
      maxDate={moment().add(max, 'years').toDate()}
    />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const contactForm1 = () => (
  <div>
    <h5 className='blueColor'>First Contact</h5>
    <div className='row'>
      <div className='col-sm-12 col-md-5'>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>First Name</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact1.firstName' type='text' component={renderField} />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Last Name</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact1.lastName' type='text' component={renderField} label='Last Name' />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Mobile</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact1.mobile' type='text' component={renderField} label='Mobile' />
            </div>
          </div>
        </div>
      </div>

      <div className='col-sm-12 col-md-6'>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Email</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact1.email' type='text' component={renderField} label='Email' />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Relationship</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field
                name='contact1.relationship'
                type='text'
                valueKey='label'
                simpleValue={true}
                clearable={false}
                component={renderDropdownList}
                options={relationship}
              />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Address</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact1.address' type='text' component={renderField} label='Address' />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <h5 className='blueColor'>Working With Children</h5>
    <div className='row'>
      <div className='col-sm-12 col-md-5'>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Type</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field
                name='contact1.wwcType'
                type='text'
                labelKey='value'
                simpleValue={true}
                component={renderDropdownList}
                options={[{ value: 'Volunteer' }, { value: 'Full' }]}
                placeholder='Type'
              />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Number</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact1.wwcNumber' type='text' component={renderField} label='Number' />
            </div>
          </div>
        </div>
      </div>
      <div className='col-sm-12 col-md-6'>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>EXP</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact1.wwcExp' type='text' component={renderDatePicker} min={-1} max={10} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Date of Birth</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact1.wwcDOB' type='text' component={renderDatePicker} min={-80} max={-15} />
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <h5 className='blueColor w250'>Emergency Contact</h5>
    <div className='d-flex'>
      <div className='w300 pr-2 d-flex'>
        <div className='pr-1'>Name:</div>
        <Field name='emergency.name' type='text' component={renderField} />
      </div>
      <div className='w300 pr-2 d-flex'>
        <div className='pr-1'>Relationship:</div>
        <Field name='emergency.relationship' type='text' component={renderField} />
      </div>
      <div className='w300 d-flex'>
        <div className='pr-1'>Mobile:</div>
        <Field name='emergency.mobile' type='text' component={renderField} />
      </div>
    </div>
  </div>
);

const contactForm2 = () => (
  <div>
    <h5 className='blueColor'>Second Contact</h5>
    <div className='row'>
      <div className='col-sm-12 col-md-5'>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>First Name</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact2.firstName' type='text' component={renderField} />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Last Name</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact2.lastName' type='text' component={renderField} label='Last Name' />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Mobile</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact2.mobile' type='text' component={renderField} label='Mobile' />
            </div>
          </div>
        </div>
      </div>

      <div className='col-sm-12 col-md-6'>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Email</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact2.email' type='text' component={renderField} label='Email' />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Relationship</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field
                name='contact2.relationship'
                type='text'
                valueKey='label'
                simpleValue={true}
                clearable={false}
                component={renderDropdownList}
                options={relationship}
              />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Address</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact2.address' type='text' component={renderField} label='Address' />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='form-group'>
      <div className='row'>
        <div className='col-sm-12 col-md-9 col-md-offset-3'>
          <Field name='contact2.sendEmail' component={renderCheckbox} />
          &nbsp; Send Emails to first and Second contacts
        </div>
      </div>
    </div>

    <hr />
    <h5 className='blueColor'>Working With Children</h5>
    <div className='row'>
      <div className='col-sm-12 col-md-5'>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Type</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field
                name='contact2.wwcType'
                type='text'
                labelKey='value'
                simpleValue={true}
                component={renderDropdownList}
                options={[{ value: 'Volunteer' }, { value: 'Full' }]}
                placeholder='Type'
              />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Number</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact2.wwcNumber' type='text' component={renderField} label='Number' />
            </div>
          </div>
        </div>
      </div>
      <div className='col-sm-12 col-md-6'>
        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>EXP</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact2.wwcExp' type='text' component={renderDatePicker} min={-1} max={10} />
            </div>
          </div>
        </div>

        <div className='form-group'>
          <div className='row'>
            <div className='col-sm-3 col-md-4'>
              <label>Date of Birth</label>
            </div>
            <div className='col-sm-8 col-md-8'>
              <Field name='contact2.wwcDOB' type='text' component={renderDatePicker} min={-80} max={-15} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ContactDetails = (props) => {
  const { handleSubmit, submitting, closeModal, changePassword, contact1 } = props;

  return (
    <div className='clearfix basic-form'>
      <form onSubmit={handleSubmit}>
        {contact1 ? contactForm1() : contactForm2()}
        <hr />
        <div>
          <div className='col-xs-6'>
            <button type='submit' disabled={submitting} className='btn btn-primary rounded'>
              Save
            </button>
            &nbsp;&nbsp;
            <button type='button' className='btn btn-default rounded' onClick={closeModal}>
              Close
            </button>
          </div>
          <div className='col-xs-6 text-right'>
            <button type='button' className='btn btn-default rounded' onClick={changePassword}>
              Change Password
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

ContactDetails.propTypes = {
  ...propTypes,
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
};

renderField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'contactDetails',
})(ContactDetails);
