import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../../actions/forms';
import ParentAgreement from '../auth/new-registration/parent-agreement-form';
import StudentDetails from '../auth/new-registration/student-details-form';
import RegistrationDetails from '../auth/new-registration/registration-details-form';

class AddStudents extends Component {

   constructor(props) {
      super(props);

      this.state = {
        page: 1
      };

      this.nextPage = this.nextPage.bind(this);
      this.previousPage = this.previousPage.bind(this);
      this.saveNewStudent = this.saveNewStudent.bind(this);
    }

    saveNewStudent = (student) => {
       this.props.addStudent(student);
       this.props.closeModal();
   }

    nextPage() {
      this.setState({ page: this.state.page + 1 });
    }

    previousPage() {
      this.setState({ page: this.state.page - 1 });
    }

    render() {
        const { forms, contact, selectedStudent, closeModal } = this.props;
        const { page } = this.state;

        if (!contact.signin) {
          return <Redirect to="/" />;
        }

        // Add questions to redux form as hidden values
        const initialValues = { schoolId: selectedStudent.schoolId, schoolName: selectedStudent.schoolName, studentContactID: contact._id, student: { photoConsent: true, registration: { questions: {} } } };
        if (this.props.forms.newReg && this.props.forms.newReg.questions) {
        this.props.forms.newReg.questions.map(question => {
            initialValues.student.registration.questions[question._id] = { 'question': question.questionText};
        });
      }

       return (
            <div className="row">
              <div className="col-md-12">
                <div>
                    {page === 1 && <ParentAgreement onSubmit={this.nextPage} agreement={forms.newReg} initialValues={initialValues} />}
                    {page === 2 && <StudentDetails previousPage={this.previousPage} onSubmit={this.nextPage} grades={this.props.school.grades} />}
                    {page === 3 && <RegistrationDetails previousPage={this.previousPage} formDetails={forms.newReg} onSubmit={this.saveNewStudent} closeModal={closeModal} />}
                </div>
              </div>
            </div>
            
        );
    }
}

AddStudents.propTypes = {
  addStudent: PropTypes.func.isRequired,
  selectedStudent: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  forms: PropTypes.object.isRequired,
  school: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    school: state.portal.school,
    forms: state.portal.forms,
    selectedStudent: state.selectedStudent,
    contact: state.contact
  };
}

export default connect(mapStateToProps, actions)(AddStudents);
