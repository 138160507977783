import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';

class DisplayMessage extends Component {

  render() {
    const { notifications } = this.props;

    //Optional styling
    const style = {
      Containers: {
        DefaultStyle: {
          fontFamily: '"Open Sans", sans-serif',
          fontWeight: 'normal'
        }
      },
      NotificationItem: { // Override the notification item
        DefaultStyle: { // Applied to every notification, regardless of the notification level
          margin: '10px 5px 2px 1px',
          padding: '17px 18px 12px 20px',
          backgroundColor: '#0f9960',
          boxShadow: 'rgba(100, 103, 93, 0.9) 0px 4px 17px',
          WebkitBoxShadow: 'rgba(100, 103, 93, 0.9) 0px 4px 17px',
          MozBoxShadow: 'rgba(100, 103, 93, 0.9) 0px 4px 17px',
          // borderRadius: '6px',
          border: '0 none',
          minHeight: '48px'
        },
        info: {
          backgroundColor: '#0f996'
        },
        error: {
          backgroundColor: '#DB3737'
        },
        warning: {
          backgroundColor: '#d9822b'
        }
      },
      Title: {
        DefaultStyle: {
          fontSize: '15px',
          color: 'white',
          margin: '0 0 5px 0',
          padding: 0,
          fontWeight: 'normal'
        }
      },
      MessageWrapper: {
        DefaultStyle: {
          color: 'white'
        }
      },
      Dismiss: {
        DefaultStyle: {
          top: '6px',
          right: '7px',
          paddingLeft: '1px',
          fontWeight: 'normal',
          backgroundColor: 'none',
          color: 'white',
          fontSize: '20px'
        },
        warning: {
          color: '#ccc'
        }
      }
    };

    return (
      <Notifications
        notifications={notifications}
        style={style}
      />
    );
  }
}

DisplayMessage.contextTypes = {
  store: PropTypes.object
};

DisplayMessage.propTypes = {
  notifications: PropTypes.array
};

function mapStateToProps(state) {
  return { notifications: state.notifications };
}

export default connect(mapStateToProps)(DisplayMessage);
