import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { required, none } from '../../common/validation';

const dropdownData = (data) => data.map((obj) => ({ value: obj }));

const renderDropdownList = ({ input, label, meta: { touched, error }, ...rest }) => (
  <div>
    <label className='col-sm-3 control-label'>{label}</label>
    <div className='col-sm-8 has-error'>
      <DropdownList {...input} {...rest} onBlur={() => input.change} />
      {touched && error && (
        <div className='admin-label text-right margin-top-quarter'>
          <i className='redBG'>{error}</i>
        </div>
      )}
    </div>
  </div>
);

const renderQuestionDropdownList = ({ input, multiOptions, meta: { touched, error }, ...rest }) => (
  <div className='has-error'>
    <DropdownList {...input} {...rest} onBlur={() => input.change} multi={multiOptions} />
    {touched && error && (
      <div className='admin-label text-right margin-top-quarter'>
        <i className='redBG'>{error}</i>
      </div>
    )}
  </div>
);

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div className={touched && error ? 'has-error' : ''}>
    <label className='col-sm-3 control-label'>{label}</label>
    <div className='col-sm-8'>
      <input className='form-control' {...input} type={type} />
      {touched && error && (
        <div className='admin-label text-right margin-top-quarter'>
          <i className='redBG'>{error}</i>
        </div>
      )}
    </div>
  </div>
);

const renderComment = ({ input, label }) => (
  <input className='form-control' {...input} placeholder={label} type='text' />
);

const instrumnetPrefence = (preference, instruments) => (
  <div className='form-group' key={preference}>
    <Field
      name={`student.registration.preference.${preference}`}
      type='text'
      component={renderDropdownList}
      options={instruments}
      valueKey='label'
      simpleValue={true}
      label={`Preference ${preference + 1}`}
      validate={preference === 0 ? required : none}
    />
  </div>
);

const instrumnetPrefences = (preferences, instruments) => (
  <div>
    <h5 className='blueColor text-center'>Instrument Preference</h5>
    {Array.apply(null, Array(preferences * 1)).map((item, i) => instrumnetPrefence(i, instruments))}
    <hr />
  </div>
);

const questionComment = (question) => (
  <div className='col-sm-12 padding-top-half'>
    <Field
      name={`student.registration.questions.${question._id}.comment`}
      type='text'
      component={renderComment}
      label={question.commentText}
    />
  </div>
);

const registrationQuestions = (question) => (
  <div key={question._id}>
    <div className='form-group'>
      <label className='col-sm-8 padding-top-one'>{question.questionText}</label>
      <div className='col-sm-4'>
        <Field
          name={`student.registration.questions.${question._id}.answer`}
          type='text'
          clearable={question.required ? false : true}
          component={renderQuestionDropdownList}
          options={dropdownData(question.answers)}
          labelKey='value'
          simpleValue={true}
          multiOptions={question.multiOptions}
          validate={question.required ? required : none}
        />
      </div>
      {question.commentAllowed && questionComment(question)}
    </div>
    <hr />
  </div>
);

const RegistrationDetails = (props) => {
  const { handleSubmit, submitting, formDetails, previousPage, closeModal } = props;

  return (
    <form onSubmit={handleSubmit} className='form-horizontal'>
      <h5 className='blueColor text-center'>Registration Details</h5>
      <div className='form-group'>
        <Field
          name='student.registration.group'
          type='text'
          valueKey='label'
          clearable={false}
          component={renderDropdownList}
          options={formDetails.ensembleList}
          simpleValue={true}
          validate={required}
          label='Ensemble'
        />
      </div>
      <hr />
      {formDetails.numberOfPreferences > 0 &&
        instrumnetPrefences(formDetails.numberOfPreferences, formDetails.instrumentList)}

      <h5 className='blueColor text-center'>Registration Questions</h5>
      <br />
      <div className='row'>
        <div className='col-sm-12'>
          <div dangerouslySetInnerHTML={{ __html: formDetails.musicProgramInfo }} />
        </div>
      </div>
      <hr />
      {formDetails.questions.map((question) => registrationQuestions(question))}

      <div className='row'>
        <div className='col-xs-6'>
          <button type='button' className='btn btn-primary' onClick={previousPage}>
            Previous
          </button>
        </div>
        <div className='col-xs-6 text-right'>
          {closeModal && (
            <button type='button' className='btn btn-default' onClick={closeModal}>
              Close
            </button>
          )}
          &nbsp;&nbsp;
          <button type='submit' className='btn btn-success' disabled={submitting}>
            Register
          </button>
        </div>
      </div>
    </form>
  );
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.func,
};

renderQuestionDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  validate: PropTypes.func,
};

renderComment.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

RegistrationDetails.propTypes = {
  ...propTypes,
};

export default reduxForm({
  form: 'newRegistration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(RegistrationDetails);
