// Validation Rules
export const required = value => value ? undefined : 'Required';
 
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value) ?
  'Invalid email address' : undefined;
  
export const number = value => value && !/^(?=.*[0-9])[- +()0-9]+$/.test(value) ? 'Must be a number' : undefined;

export const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const maxLength = max => value => value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const none = () => undefined;

export const minLength5 = minLength(5);