import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import ForgotPasswordForm from './forgot-password-form';
import { Link } from 'react-router-dom';

class ForgotPassword extends Component {
    
    restPassword = (values) => {
        this.props.resetPassword(values);
   }

    render() {
        const { school } = this.props;

        return (
          <div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="navigation-style clearfix" style={{'backgroundColor': school.schoolColour}}>
                        <a className={(school.hasLogo) ? 'nav-logo pull-left' : 'nav-logo pull-left no-logo'} href="#">{(school.hasLogo) && <img src={`https://portal.msmusic.com.au/${school._id}.png`} alt="logo"/>} {(!school.hideName) && school.name}</a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-offset-3 col-sm-6 col-md-offset-4 col-md-4">
                    <div className="login-widget">
                        <div className="login-box">
                            <h3 className="blueBG">Reset Password</h3>
                            <div className="forget-pass">
                                <ForgotPasswordForm onSubmit={this.restPassword} />
                            </div>
                        </div>
                        <div className="register">
                            <p>Know your password? <Link to="signin">Sign In</Link></p>
                        </div>
                    </div>
                </div>
            </div>
           </div>
        );
    }
}

ForgotPassword.propTypes = {
    resetPassword: PropTypes.func.isRequired,
    school: PropTypes.object.isRequired
};

function mapStateToProps(state) {
 
  return {
    school: state.portal.school
  };
}

export default connect(mapStateToProps, actions)(ForgotPassword);

