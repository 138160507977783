import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import portal from './portal-reducer';
import contact from './contact-reducer';
import selectedStudent from './students-reducer';
import { reducer as notifications} from 'react-notification-system-redux';

const rootReducer = combineReducers({
  contact,
  form,
  selectedStudent,
  portal,
  notifications
});

export default rootReducer;