import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../../actions';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import Header from '../common/header';
import Footer from '../common/footer';
import Calendar from '../events/events-page';
import GroupDetails from './group-details';
import DocumentsModule from './documents-module';
import PortalMessage from './portal-message';
import Enrolment from '../forms/enrolment-page';
import Accounts from './accounts-module';
import Invoice from './invoice-modal';
import AddStudent from '../forms/student-registration-page';
import UpdateStudent from '../forms/update-student-page';
import ReEnrolment from '../forms/re-enrolment-page';
import CustomForms from '../forms/custom-forms-page';

class Dashboard extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showModal: false,
      modalHeading: '',
      studentInv: {},
    };
  }

  componentDidMount() {
    const { contact, selectedStudent } = this.props;

    if (selectedStudent) {
      this.props.getEnrolments();
      this.props.getCustomForms();
      this.props.getStudentDetails(selectedStudent.studentId, selectedStudent.schoolId, this.props.history);
      this.props.getInvoices(contact.students.map((student) => student.studentId));
      if (contact.enrolments && contact.enrolments.length > 0) this.openModal('Re-Enrolment');
      if (contact.customForms && contact.customForms.length > 0) this.openModal('Required Form');
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedStudent, contact } = this.props;
    if (selectedStudent.studentId !== nextProps.selectedStudent.studentId) {
      this.props.getStudentDetails(
        nextProps.selectedStudent.studentId,
        nextProps.selectedStudent.schoolId,
        this.props.history
      );
    }

    if (!nextProps.contact.updated) {
      if (contact !== nextProps.contact && nextProps.contact.enrolments && nextProps.contact.enrolments.length > 0)
        this.openModal('Re-Enrolment');
      if (contact !== nextProps.contact && nextProps.contact.customForms && nextProps.contact.customForms.length > 0)
        this.openModal('Required Form');
    }
  }

  openModal = (modal, studentInv = {}) => {
    this.setState({ showModal: true, modalHeading: modal, studentInv });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  changeStudent = (student) => {
    if (student.studentId !== this.props.selectedStudent.studentId) this.props.changeStudent(student);
  };

  downloadFile = (document) => {
    this.props.downloadFile(document);
  };

  studentList = (students) => students.map((student) => ({ value: student.studentId, label: student.studentName }));

  render() {
    const { portal, selectedStudent, contact, forms } = this.props;

    if (!contact.signin) {
      return <Redirect to='/' />;
    }

    if (!selectedStudent.groups) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <Header />
        <div className='main-content'>
          <br />
          <PortalMessage groups={selectedStudent.groups} />
          <div className='row'>
            <div className='col-md-7 col-sm-7 col-xs-12'>
              <div className='panel panel-default'>
                <div className='panel-body'>
                  <Calendar />
                </div>
              </div>
              {!selectedStudent.hideAccounts && (
                <Accounts invoices={this.props.portal.studentInv} openModal={this.openModal} />
              )}
            </div>

            <div className='col-md-5 col-sm-5 col-xs-6'>
              <div className='alert-widget'>
                <div className='widget-head greenBG'>
                  <div className='row'>
                    <div className='col-sm-8'>
                      <Select
                        name='student'
                        className='selectedStudent'
                        style={{ backgroundColor: '#76c80e', borderColor: '#76c80e', fontWeight: '700' }}
                        options={contact.students.filter((student) => !student.archived)}
                        valueKey='studentId'
                        labelKey='studentName'
                        clearable={false}
                        arrowRenderer={() => ''}
                        valueRenderer={(value) => (
                          <div>
                            {value.studentName} &nbsp;<i className='fa fa-caret-down'></i>
                          </div>
                        )}
                        onChange={(value) => this.changeStudent(value)}
                        value={selectedStudent.studentId}
                      />
                    </div>
                    <div className='col-sm-4 text-right'>
                      {forms.newReg && forms.newReg.newRegActive && (
                        <button
                          type='button'
                          className='btn btn-xs btn-success'
                          onClick={() => this.openModal('Add a Student')}
                        >
                          Add Student
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className='white-background'>
                  <div className='student-actions text-right'>
                    {contact.customForms &&
                      contact.customForms.filter((item) => item.customForms.length > 0).length > 0 && (
                        <button
                          type='button'
                          className='btn btn-xs btn-warning'
                          onClick={() => this.openModal('Required Form')}
                        >
                          Required Form
                        </button>
                      )}
                    &nbsp;
                    {contact.enrolments.length > 0 && (
                      <button
                        type='button'
                        className='btn btn-xs btn-success'
                        onClick={() => this.openModal('Re-Enrolment')}
                      >
                        Re-Enrolment
                      </button>
                    )}
                    &nbsp;
                    {forms.enrolment && forms.enrolment.enrolmentActive && (
                      <button
                        type='button'
                        className='btn btn-xs btn-primary'
                        onClick={() => this.openModal('Ensemble Enrolment')}
                      >
                        Add Ensemble
                      </button>
                    )}
                    &nbsp;
                    <button
                      type='button'
                      className='btn btn-xs btn-info'
                      onClick={() => this.openModal('Update Student')}
                    >
                      Update Student
                    </button>
                  </div>
                  <div className='alert-list'>
                    <GroupDetails groups={selectedStudent.groups} enrolments={selectedStudent.enrolments} />
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-5 col-sm-5 col-xs-6'>
              <DocumentsModule documents={portal.documents} downloadFile={this.downloadFile} />
            </div>
          </div>
          <br />
          <br />
        </div>
        <Footer />
        <Modal show={this.state.showModal} onHide={this.closeModal} bsSize='lg'>
          <Modal.Header bsClass='modal-header primary' closeButton>
            <Modal.Title>{this.state.modalHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-padding'>
              {this.state.modalHeading === 'Add a Student' && (
                <AddStudent
                  school={contact.selectedStudent}
                  contact={contact.contactDetails}
                  closeModal={this.closeModal}
                />
              )}
              {this.state.modalHeading === 'Update Student' && (
                <UpdateStudent studentId={selectedStudent.studentId} closeModal={this.closeModal} />
              )}
              {this.state.modalHeading === 'Ensemble Enrolment' && (
                <Enrolment onSubmit={this.saveEnrolment} closeModal={this.closeModal} />
              )}
              {this.state.modalHeading === 'Invoice' && (
                <Invoice
                  schoolInv={this.props.portal.schoolInv}
                  studentInv={this.state.studentInv}
                  closeModal={this.closeModal}
                  wait={portal.wait}
                />
              )}
              {this.state.modalHeading === 'Re-Enrolment' && (
                <ReEnrolment enrolments={contact.enrolments} closeModal={this.closeModal} />
              )}
              {this.state.modalHeading === 'Required Form' && (
                <CustomForms customForms={contact.customForms} closeModal={this.closeModal} />
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

Dashboard.propTypes = {
  portal: PropTypes.object.isRequired,
  selectedStudent: PropTypes.object.isRequired,
  forms: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  getStudentDetails: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  getEnrolments: PropTypes.func.isRequired,
  changeStudent: PropTypes.func.isRequired,
  getInvoices: PropTypes.func.isRequired,
  getCustomForms: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    portal: state.portal,
    selectedStudent: state.selectedStudent,
    forms: state.portal.forms,
    contact: state.contact,
  };
}

export default connect(mapStateToProps, actions)(Dashboard);
