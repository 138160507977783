import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

const renderMessages = groups => {
    return groups.map((group, index) => {
       if (_.isEmpty(group.groupMessage)) return null;

        return (
            <div key={index} className="row">
              <div className="col-sm-12">
                <div className="alert alert-info"><strong>{group.groupMessage.title}</strong>&nbsp;&nbsp;{group.groupMessage.message}</div>
              </div>
            </div>
        );
    });
};

const renderNoGroupsMessage = () => (
            <div key="1" className="row">
              <div className="col-sm-12">
                <div className="alert alert-info"><strong>Welcome to the Portal</strong><br />Your Registration has been received, once processed you will see information relevant to the bands you have been placed into</div>
              </div>
            </div>
     );


const PortalMessage = (props) => {
  const { groups } = props;
    return (
        <div>
            { (groups.length > 0) ? renderMessages(groups) : renderNoGroupsMessage()}
        </div>
    );
};


PortalMessage.propTypes = {
    groups: PropTypes.array.isRequired
};

export default PortalMessage;