import axios from "axios";
import Notifications from "react-notification-system-redux";
import fileDownload from "js-file-download";
import { reset } from 'redux-form';
import moment from "moment";
import { ROOT_URL, UPDATE_SCHOOL, GET_SCHOOL, SIGN_IN, SIGN_OUT, SET_STUDENT, SET_CONTACT,
          GET_STUDENT_GROUPS, GET_EVENTS, SET_ENROLMENTS, SET_INVOICE, PWD_TOKEN, 
          GET_DOCUMENTS, GET_FORM, SET_CUSTOM_FORMS, TOGGLE_WAIT } from "./types";

// Return Default MSM Branding Details
export function getMSM() {
  return { type: GET_SCHOOL, payload: { _id: 0, name: "My School Music", messages: [] } };
}

// Get School Branding Details
export function getSchool(id) {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/portal/school/${id}`)
      .then(response => {
        dispatch({ type: GET_SCHOOL, payload: response.data }); 
      })
      .catch(error => {
        throw error;
      });
  };
}

// Set School details to register new student
export function selectSchool(id, history) {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/portal/school/${id}`)
      .then(response => {
        if (response.data._id) {
          dispatch({
            type: GET_SCHOOL,
            payload: response.data
          });

          history.push("/add-student");
        } else {
          dispatch(Notifications.error({ title: "School Not Found" }));
        }
      })
      .catch(error => {
        throw error;
      });
  };
}

// Signin user with email and password
export function signinUser(signin, history) {

  return function(dispatch) {
    axios
      .post(`${ROOT_URL}/portal/signin`, signin)
      .then(response => {
        response.data.sContact.signin = true;
        if (signin.remember) response.data.sContact.remember = true;
        dispatch({ type: SIGN_IN, payload: response.data.sContact });
        dispatch({ type: SET_STUDENT, payload: response.data.sContact.students[0] });

        localStorage.setItem("token", response.data.token);
        //  if (!response.data.sContact.verified) {
        //       browserHistory.push('/verify');
        //     } else {
        //        browserHistory.push('/dashboard');
        //     }
        history.push("/dashboard");
      })
      .catch(() => {
        dispatch(
          Notifications.error({
            title: "Login Error",
            message: "Invalid email or password"
          })
        );
      });
  };
}

// Signin user using saved token
export function tokenSignin(token, history) {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/portal/signin`, {
        headers: { authorization: token }
      })
      .then(response => {
        response.data.sContact.signin = true;
        response.data.sContact.remember = true;
        dispatch({ type: SIGN_IN, payload: response.data.sContact });
        dispatch({ type: SET_STUDENT, payload: response.data.sContact.students[0] });

        history.push("/dashboard");
      })
      .catch(() => {
        localStorage.removeItem("token");
        history.push("/signout");
        dispatch(Notifications.error({ title: "Login Error", message: "Unable to login contact administrator" }));
      });
  };
}

// sign user out
export function signout(remember) {
  if (!remember) localStorage.removeItem("token");
  return { type: SIGN_OUT };
}

// Get Student Details
export function getStudentDetails(id, schoolId, history) {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/portal/student/${id}/${schoolId}`, {
        headers: { authorization: localStorage.getItem("token") }
      })
      .then(response => {
        const eventList = response.data.events.map(event => {
          event.eventStart = moment(event.eventStart).toDate();
          event.eventEnd = moment(event.eventEnd).toDate();
          return event;
        });
        dispatch({ type: GET_STUDENT_GROUPS, groups: response.data.school.groups, enrolments: response.data.school.enrolments });
        dispatch({ type: UPDATE_SCHOOL, payload: response.data.school });
        dispatch({ type: GET_EVENTS, payload: eventList });
        dispatch({ type: GET_FORM, payload: response.data.forms });
        dispatch({ type: GET_DOCUMENTS, payload: response.data.documents });
      })
      .catch(() => {
        dispatch(Notifications.error({ title: "Portal Error", message: "Unable to get student details, please try login again" }));
        localStorage.removeItem("token");
        history.push("/");
      });
  };
}

export function getEnrolments() {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/portal/enrolments`, {
        headers: { authorization: localStorage.getItem("token") }
      })
      .then(response => {
        dispatch({ type: SET_ENROLMENTS, payload: response.data });
      })
      .catch(err => {
        throw err;
      });
  };
}

export function getCustomForms() {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/portal/custom-forms`, {
        headers: { authorization: localStorage.getItem("token") }
      })
      .then(response => {
        dispatch({ type: SET_CUSTOM_FORMS, payload: response.data });
      })
      .catch(err => {
        throw err;
      });
  };
}

// Switch Students
export function changeStudent(selectedStudent) {
  return { type: SET_STUDENT, payload: selectedStudent };
}

// Save new Portal signup
export function signUp(studentContact, history) {
  return function(dispatch) {
    axios
      .post(`${ROOT_URL}/portal/registration`, studentContact, {
        headers: { authorization: localStorage.getItem("token") }
      })
      .then(response => {
        dispatch(
          Notifications.success({
            title: "Registration Received",
            message: response.data.message
          })
        );
        dispatch({ type: SIGN_IN });

        // Save Token
        localStorage.setItem("token", response.data.token);

        // Direct to admin page
        history.push("/dashboard");
      })
      .catch(err => {
        dispatch(
          Notifications.error({
            title: "Registration Error",
            message: err.response.data
          })
        );
      });
  };
}

// Update Parent contact portal password
export function updatePassword(password) {
  return function(dispatch) {
    axios
      .post(`${ROOT_URL}/portal/new-password`, password, {
        headers: { authorization: localStorage.getItem("token") }
      })
      .then(response => {
        dispatch(
          Notifications.success({
            title: "Password Updated",
            message: response.data.message
          })
        );
      })
      .catch(err => {
        dispatch(
          Notifications.error({
            title: "Could Not Update Password",
            message: err.response.data
          })
        );
      });
  };
}

// Update Parent contact portal password
export function verifyEmail(id, history) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/portal/verify/${id}`, {
        headers: { authorization: localStorage.getItem("token") }
      })
      .then(response => {
        dispatch({ type: GET_SCHOOL, payload: response.data }); 
        dispatch( Notifications.success({ title: "Email Verified", message: "Thank you, your email has been verified" }));
        history.push(`/${response.data.portalURL}`);
      })
      .catch(() => { dispatch( Notifications.error({ title: "Could Not Verify Email" })
        );
      });
  };
}

export function downloadFile(file) {
  axios
    .get(`${ROOT_URL}/portal/download/${file.id}`, {
      headers: { authorization: localStorage.getItem("token") },
      responseType: "arraybuffer"
    })
    .then(response => {
      fileDownload(response.data, file.fileName);
    });

  return {
    type: "NO_ACTION",
    payload: ""
  };
}


// Update Parent contact portal password
export function getRegistrationDetails(id, history) {
  return function(dispatch) {
    axios.get(`${ROOT_URL}/portal/registration/${id}`)
      .then(response => {
        if (response.status === 201) {
          dispatch( Notifications.success({ title: 'Student added to your portal account' }));
          history.push(`/${response.data}`);
        } else {
        dispatch({ type: GET_SCHOOL, payload: response.data.school });
        dispatch({ 
          type: SET_CONTACT,
          contact1: response.data.student.studentContact.contact1,
          contact2: response.data.student.studentContact.contact2 || {}
        }); 
        }
      })
      .catch((err) => { 
        if (!err.response.data.error) {
          dispatch( Notifications.error({ title: 'A system error occured' }));
        } else {
          dispatch( Notifications.error({ title: err.response.data.error }));
        }
        history.push(`/`);
       
      });
  };
}

// Update Parent contact portal password
export function saveRegistration(contactDetails, school, id, history) {
  return function(dispatch) {
    axios.post(`${ROOT_URL}/portal/registration/${id}`, { contactDetails, school })
      .then((response) => {
        dispatch( Notifications.success({ title: "Portal Registration Saved" }));
        localStorage.setItem('token', response.data);   

        return axios.get(`${ROOT_URL}/portal/signin`, {
          headers: { authorization: response.data } });
      }).then(response => {
        response.data.sContact.signin = true;
        response.data.sContact.remember = true;
        dispatch({ type: SIGN_IN, payload: response.data.sContact });
        dispatch({ type: SET_STUDENT, payload: response.data.sContact.students[0] });

        history.push("/dashboard");
      })
      .catch(() => dispatch( Notifications.error({ title: "Could Not Save Your Details" })));
  };
}

export function getInvoice(id) {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/invoice/${id}`)
      .then(response => {
        dispatch({ type: SET_INVOICE, payload: response.data });
      })
      .catch(err => {
        throw err;
      });
  };
}

export function resetPassword(email) {
  return function(dispatch) {
      axios.post(`${ROOT_URL}/portal/password`, email)
      .then(() => {
          dispatch(Notifications.success({ title: 'Reset password link sent' }));
          dispatch(reset('forgotPassword'));

        }).catch(() => {
          dispatch(Notifications.error({ title: 'Email address not found' }));
        });
    };
}

export function checkPwdtoken(token) {
  return function(dispatch) {
      axios.get(`${ROOT_URL}/portal/password/${token}`)
      .then(() => {
        
        // token Valid
        dispatch( {type: PWD_TOKEN, payload: true} );

        // token Not Valid
        }).catch(() => {
          dispatch( {type: PWD_TOKEN, payload: false} );
          dispatch(Notifications.error({ title: 'Password link is invalid or has expired', message: 'Request a new link through the \'Forgot Password\' Option', autoDismiss: 10 }));
        });
    };
}

export function savePassword(pwd, token, history) {
  return function(dispatch) {
      axios.put(`${ROOT_URL}/portal/password/${token}`, pwd)
      .then(response => {
        // Save token
        localStorage.setItem('token', response.data);   

        return axios.get(`${ROOT_URL}/portal/signin`, {
          headers: { authorization: response.data } });
      }).then(response => {
        response.data.sContact.signin = true;
        response.data.sContact.remember = true;
        dispatch({ type: SIGN_IN, payload: response.data.sContact });
        dispatch({ type: SET_STUDENT, payload: response.data.sContact.students[0] });

        history.push("/dashboard");
      })
        .catch((error) => dispatch(Notifications.error({ title: "Password link is invalid or has expired" })));
       
    };
}

export function getInvoices(id) {
  return function(dispatch) {
    axios
      .put(`${ROOT_URL}/invoices`, { id }, {
        headers: { authorization: localStorage.getItem("token") }
      })
      .then(response => {
        dispatch({ type: SET_INVOICE, payload: response.data });
      })
      .catch(err => {
        throw err;
      });
  };
}

export function getInvoicePdf(id, invNumber) {
  return function(dispatch) {
    dispatch({ type: TOGGLE_WAIT });
    axios
      .get(`${ROOT_URL}/invoice-pdf/${id}`, {
        headers: { authorization: localStorage.getItem("token") },
        responseType: "arraybuffer"
      })
      .then(response => {
        fileDownload(response.data, `Invoice_${invNumber}.pdf`);
        dispatch({ type: TOGGLE_WAIT });
      });
  };
}

