import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../actions';
import { Modal } from 'react-bootstrap';
import ContactDetails from '../forms/contact-details-page';
import ChangePassword from '../contact/password-form';

class Header extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            large: true,
            modalHeading: ''
        };
        
    }

    openModal = (modalHeading) => {
         this.setState({ showModal: true, large: true, modalHeading });
    };

    closeModal = () => {
         this.setState({ showModal: false, modalHeading: '' });
    };

    changePassword = () => {
        this.setState({ large: false, modalHeading: 'Change Password' });
   };

   updatePassword = (password) => {
        this.props.updatePassword(password);
    }

    changeStudent = (student) => {
       if (student.studentId !== this.props.selectedStudent.studentId) this.props.changeStudent(student);
    };

    signout = () => {
        if (!this.props.contact.remember) localStorage.removeItem('token');
        this.props.history.push("/signout");
    };

    render() {
        const { selectedStudent, school, contact } = this.props;

        if (!school) {
            return null;
        }
        
        return (
        <div>
            <div className="row">
                <div className="col-sm-12">
                <div className="navbar-box">
                    <div className="navigation-style clearfix" style={{'backgroundColor': school.schoolColour}}>
                        
                        <a className={(selectedStudent.hasLogo) ? 'nav-logo pull-left' : 'nav-logo pull-left no-logo'} href="#">{(selectedStudent.hasLogo) && <img src={`https://portal.msmusic.com.au/${selectedStudent.schoolId}.png`} alt="logo"/>} {(!selectedStudent.hideName) && selectedStudent.schoolName}</a>
                        <ul className="pull-right list-unstyled">
                            <li><a onClick={() => this.openModal('Contact Details')}><i className="fa fa-user"></i> {`${contact.contact1.firstName} ${contact.contact1.lastName}`}</a></li>
                            <li><a onClick={() => this.signout()}><i className="fa fa-sign-out"></i></a></li>
                        </ul>
                    </div>
                </div>
                </div>
            </div>
            <Modal show={this.state.showModal} onHide={this.closeModal} bsSize={this.state.large ? 'lg' : 'md'}>
                    <Modal.Header bsClass="modal-header primary" closeButton>
                        <Modal.Title>{this.state.modalHeading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="clearfix form-padding">
                            {(this.state.modalHeading === 'Contact Details') && <ContactDetails closeModal={this.closeModal} changePassword={this.changePassword}/>}
                            {(this.state.modalHeading === 'Change Password') && <ChangePassword onSubmit={this.updatePassword} closeModal={this.closeModal} changePassword={this.changePassword}/>}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

Header.propTypes = {
    contact: PropTypes.object.isRequired,
    selectedStudent: PropTypes.object.isRequired,
    changeStudent: PropTypes.func.isRequired,
    school: PropTypes.object.isRequired,
    forms: PropTypes.object.isRequired,
    updatePassword: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
       school: state.portal.school,
       forms: state.portal.forms,
       contact: state.contact,
       selectedStudent: state.selectedStudent
    };
}

export default withRouter(connect(mapStateToProps, actions)(Header));



