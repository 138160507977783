import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Portal from './components/auth/portal-page';
import Registration from './components/auth/registration-page';
import ForgotPassword from './components/auth/forgot-password-page';
import UpdatePassword from './components/auth/update-password-page';
import Dashboard from './components/dashboard/dashboard-page';
import Events from './components/events/events-page';
import SignOut from './components/auth/signout-page';
import Verify from './components/auth/verify-page';
import Contact from './components/contact/contact-page';
import SelectSchool from './components/forms/select-school-page';
import AddStudent from './components/forms/student-registration-page';
import ChangePassword from './components/contact/password-page';
import SingleNewStudents from './components/auth/single-new-students-page';
import NewStudents from './components/auth/new-registration/new-registration-page';
import Invoice from './components/auth/invoice/invoice-page';
import DisplayMsg from './components/common/display-message';


const menuItem = <Switch>
  <Route path="/signin" exact component={Portal} />
  <Route path="/msm-signin" exact component={Portal} />
  <Route path="/signout" exact component={SignOut} />
  <Route path="/registration/:id" exact component={Registration} />
  <Route path="/password" exact component={ForgotPassword} />
  <Route path="/password/:token" exact component={UpdatePassword} />
  <Route path="/dashboard" exact component={Dashboard} />
  <Route path="/events" exact component={Events} />
  <Route path="/contact" exact component={Contact} />
  <Route path="/select-school" exact component={SelectSchool} />
  <Route path="/add-student" exact component={AddStudent} />
  <Route path="/new-password" exact component={ChangePassword} />
  <Route path="/verify/:id" exact component={Verify} />
  <Route path="/register/single" exact component={SingleNewStudents} />
  <Route path="/register" exact component={NewStudents} />
  <Route path="/invoice/:id" exact component={Invoice} />
  <Route path="/:id?" exact component={Portal} />
  <Redirect to="/" />
</Switch>

const App = () => (
  <React.Fragment>
    <DisplayMsg />
    {menuItem}
  </React.Fragment>
);

export default App;
