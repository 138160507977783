import React from 'react';
import PropTypes from 'prop-types';

const renderForms = (customForms, selectForm) => {
    return customForms.reduce((formList, student, studentIndex) => {
      student.customForms.forEach((form, formIndex) => formList.push(
        <li key={`${studentIndex}-${formIndex}`} onClick={() => selectForm(studentIndex, formIndex, { id: student._id, name: student.firstName }, { id: form.formId, title: form.formTitle })}>
          <div className="client-info clearfix">
            <div className="client-name pull-left">
              <div className="col-sm-12">
                <strong>{form.formTitle}</strong>
              </div>
              <div className="client-loc text-muted col-sm-12">
                Student: {student.firstName}
              </div>
            </div>
            <div className="find-more pull-right">
                <i className="fa fa-chevron-right blueColor"></i>
            </div>
          </div>
        </li>
      ))
      return formList;
    }, []);
};

const CustomFormList = (props) => {
  const { customForms, selectForm } = props;

    return (
        <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
            <h4 className="text-center">Select a Form</h4>
            <div className="clients-widget xs-m-30">
                <div className="widget-content">
                    <ul className="list-unstyled">
                        {renderForms(customForms, selectForm)} 
                    </ul>
                </div>
            </div>
            </div>
        </div>
    );
};

CustomFormList.propTypes = {
  customForms: PropTypes.array.isRequired,
  selectForm: PropTypes.func.isRequired
};

export default CustomFormList;