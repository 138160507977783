import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RegistrationForm from './single-new-students-form';
import DisplayMsg from '../common/display-message';
import * as actions from '../../actions/';

class SingleNewStudents extends Component {
    
    componentDidMount() {
        this.props.getNewStudentForm(this.props.school._id);
    }

     saveNewStudent = (values) => {
        this.props.singleNewRegistration(values, this.props.school.schoolId);
   }

    render() {

        const { forms, school } = this.props;

          if (!forms.newReg) {
              if (school._Id === 0) {
                return (
                <div className="col-sm-offset-3 col-sm-6 col-md-offset-4 col-md-4">
                    <div className="panel panel-primary margin-top-two">
                        <div className="panel-heading">Registration page not found</div>
                        <div className="panel-body">
                            check that you have the correct url
                        </div>
                    </div>
                </div>
                
                );
              } else {
                return (<div>Loading...</div>);
              }
            
        }   

        // Add questions to redux form as hidden values
        const initialValues = { questions: {} };
        this.props.forms.newReg.questions.map(question => {
            initialValues.questions[question._id] = { 'question': question.questionText};
        });

        return (
           <div>
           <DisplayMsg />
                <div className="row">
                    <div className="col-sm-12">
                        <div className="navigation-style clearfix" style={{'backgroundColor': this.props.school.schoolColour}}>
                            <a className="nav-logo pull-left" href="#">{school.name}</a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-offset-2 col-sm-8">
                        <div className="panel panel-default">
                                <div className="panel-heading">New Student Registration</div>
                                <div className="panel-body">
                                   <RegistrationForm regDetails={forms.newReg} grades={[]} initialValues={initialValues} onSubmit={this.saveNewStudent} />
                                </div>
                            </div>
                        
                    </div>
            </div>
            <footer>
                <div className="copyrights">© Copyrights 2017 <a href="www.myschoolmusic.com.au/" target="_blank">My School Music</a> All Rights Reserved.
                </div>
            </footer>
        </div>
        );
    }
}

SingleNewStudents.propTypes = {
  getNewStudentForm: PropTypes.func.isRequired,
  singleNewRegistration: PropTypes.func.isRequired,
  forms: PropTypes.object.isRequired,
  school: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    school: state.school,
    forms: state.forms
  };
}

export default connect(mapStateToProps, actions)(SingleNewStudents);
