import React from 'react';
import PropTypes from 'prop-types';

const renderStudents = (students, selectStudent) => {
    return students.map((student, index) => {
        return (
            <li key={index} onClick={() => selectStudent(index)}>
              <div className="client-info clearfix">
                <div className="client-name pull-left">
                  <strong>{student.firstName}</strong>
                </div>
                <div className="find-more pull-right">
                    <i className="fa fa-chevron-right blueColor"></i>
                </div>
              </div>
            </li>
        );
    });
};

const SelectStudent = (props) => {

  const { students, selectStudent } = props;

    return (
        <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
            <h4 className="text-center">Select a student</h4>
            <div className="clients-widget xs-m-30">
                <div className="widget-content">
                    <ul className="list-unstyled">
                        {renderStudents(students, selectStudent)}
                    </ul>
                </div>
            </div>
            </div>
        </div>
    );
};

SelectStudent.propTypes = {
    students: PropTypes.array.isRequired,
    selectStudent: PropTypes.func.isRequired
};

export default SelectStudent;