import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import DropdownList from 'react-select';
import { Checkbox } from "@blueprintjs/core";
import { DateInput } from '@blueprintjs/datetime';
import { required } from '../../common/validation';
import moment from 'moment';

 const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div className={(touched && error) ? 'has-error' : ''}>
    <label className="col-sm-3 control-label">{label}</label>
    <div className="col-sm-8">
         <input className="form-control" {...input} type={type}/>
            { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
  </div>
  );

  const renderDropdownList = ({ input, label, meta: { touched, error }, ...rest }) => (
    <div>
        <label className="col-sm-3 control-label">{label}</label>
        <div className="col-sm-8 has-error">
            <DropdownList {...input} {...rest} onBlur={() => input.change} />
            { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
        </div>
    </div>
    );

const renderDatePicker = ({ input, label, meta: { touched, error } }) => (
    <div>
        <label className="col-sm-3 control-label">{label}</label>
        <div className="col-sm-8">
            <DateInput
            value={input.value}
            onChange={input.onChange}
            format={'DD MMM YYYY'}
            minDate={moment().add(-18, 'years').toDate()}
            maxDate={moment().add(-4, 'years').toDate()}
            />
            {touched && error && (
            <div className='admin-label text-right margin-top-quarter'>
                <i className='redBG'>{error}</i>
            </div>
            )}
        </div>
    </div>
    );

  const renderCheckbox = ({ input }) => 
    <label className="col-sm-offset-3 pt-control pt-checkbox pt-large text-left">
        <Checkbox checked={input.value} onChange={input.onChange} label="&nbsp;&nbsp;Photo consent" /> 
        <p style={{ fontSize: 10, paddingLeft: 30, paddingRight: 30, color: 'gray' }}>I give permission to the Music Program to take photos / videos of my child and to upload promotional photos / videos to newsletters, the P&C website and the school website</p>
    </label>;
 
 const StudentDetails = (props) => {
    
    const { handleSubmit, previousPage, grades } = props;

    return (
        <form onSubmit={handleSubmit} className="form-horizontal">
            <h5 className="blueColor text-center">Student Details</h5>
            <div className="form-group">
                <Field name="student.firstName" type="text" component={renderField} label="First Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="student.lastName" type="text" component={renderField} label="Last Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="student.gender" type="text" clearable={false} component={renderDropdownList} options={[{ value: 'Male'}, { value: 'Female'}]} labelKey="value" simpleValue={true} validate={required} label="Gender" />  
            </div>
            <div className="form-group">
                <Field name='student.DOB' label="Date of Birth" type='text' component={renderDatePicker}  />
            </div>
            <div className="form-group">
                <Field name="student.grade" type="text" clearable={false} component={renderDropdownList} options={grades} valueKey="label" simpleValue={true} validate={required} label="Grade" />   
            </div>
            <div className="form-group">
                <Field name="student.class" type="text" component={renderField} label="Class" />  
            </div>
            <div className="form-group">
                <Field name="student.photoConsent" id="photoConsent" component={renderCheckbox} />
            </div>

            <hr />
            <div className="row">
              <div className="col-xs-6">
                  <button type="button" className="btn btn-primary" onClick={previousPage}>Previous</button>
              </div>
              <div className="col-xs-6 text-right">
                  <button type="submit" className="btn btn-primary">Next</button>
              </div>
            </div>
        </form>
    );
};

renderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.func
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired
};

StudentDetails.propTypes = {
  ...propTypes
};

export default reduxForm({
    form: 'newRegistration',
    destroyOnUnmount: false, 
    forceUnregisterOnUnmount: true
})(StudentDetails);