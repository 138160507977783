import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/forms';
import EnrolmentForm from './enrolment-form';

class EnrolmentPage extends Component {

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
      this.props.getEnrolmentOptions(this.props.selectedStudent.studentId);
    }

    saveEnrolment = (values) => {
      const enrolment = _.pick(values, ['comment']);
      const groups = values.groups.map(group => ({ groupId: group.group.groupId, name: group.group.name, shortName: group.group.shortName, instrument: group.instrument }));
      enrolment.groups = groups;
      this.props.saveEnrolment(enrolment, this.props.selectedStudent.studentId);
      this.props.closeModal();
  }

    render() {
        const { closeModal, portal } = this.props;

        if (!portal.forms.options) return null;

        return (
          <div>
            <EnrolmentForm onSubmit={this.saveEnrolment} enrolmentForm={portal.forms} closeModal={closeModal} />
          </div>
        );
    }
}

EnrolmentPage.propTypes = {
  portal: PropTypes.object.isRequired,
  selectedStudent: PropTypes.object.isRequired,
  saveEnrolment: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getEnrolmentOptions: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
      portal: state.portal,
      selectedStudent: state.selectedStudent
    };
}

export default connect(mapStateToProps, actions)(EnrolmentPage);