import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as actions from "../../actions";
import Autosuggest from "react-autosuggest";
import Header from "../common/header";
import Footer from "../common/footer";

const schoolsList = [
  {
    name: "Test School 1",
  },
  {
    name: "St Ives North",
  },
  {
    name: "Test School 2",
  },
];

const getSuggestions = (value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength < 2
    ? []
    : schoolsList.filter(
        (school) =>
          school.name.toLowerCase().slice(0, inputLength) === inputValue
      );
};

const getSuggestionValue = (suggestion) => suggestion.name;

const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

class SelectSchool extends Component {
  constructor() {
    super();

    this.state = {
      value: "",
      suggestions: [],
    };
  }

  setSchool = (e) => {
    e.preventDefault();
    this.props.selectSchool(this.state.value, this.props.history);
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const { contact } = this.props;

    if (!contact.signin) {
      return <Redirect to='/' />;
    }

    // Autosuggest will pass through all these props to the input element.
    const inputProps = {
      placeholder: "Find your child's school",
      value,
      onChange: this.onChange,
    };

    return (
      <div>
        <div className='main-content'>
          <Header />
          <div className='page-links'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>New Student Registration</li>
              <li className='breadcrumb-item pull-right'>
                Welcome {contact.contactDetails.contact1.firstName}
              </li>
            </ol>
          </div>
          <br />
          <div className='row'>
            <div className='col-md-8 col-md-offset-2'>
              <div className='panel panel-primary'>
                <div className='panel-heading'>Select School</div>
                <div className='panel-body'>
                  <form onSubmit={this.setSchool} className='form-horizontal'>
                    <div className='form-group'>
                      <label className='col-sm-3 control-label'>
                        School Name
                      </label>
                      <div className='col-sm-6'>
                        <Autosuggest
                          className='form-control'
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequested
                          }
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={renderSuggestion}
                          inputProps={inputProps}
                        />
                      </div>
                      <div className='col-sm-3'>
                        <button
                          type='submit'
                          onClick={this.setSchool}
                          className='submit-btn pull-right'
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </form>
                  <p>
                    Start typing the first few letters of your child's school
                    name, a list of matching schools will be displayed. <br />
                    If you do not see your childs school check that you have
                    entered the school name correctly, and that they are using
                    My School Music to administer their music program.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
        <Footer />
      </div>
    );
  }
}

SelectSchool.propTypes = {
  contact: PropTypes.object.isRequired,
  school: PropTypes.object.isRequired,
  selectSchool: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    contact: state.contact,
    school: state.school,
  };
}

export default connect(mapStateToProps, actions)(SelectSchool);
