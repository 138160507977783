import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../actions/index";
import { Link } from "react-router-dom";
import SignInForm from "./signin-form";

class Signout extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      displaySignin: false,
    };
  }

  componentDidMount() {
    this.props.signout(this.props.remember);
  }

  signBackIn = () => {
    const token = localStorage.getItem("token");
    if (token) {
      this.props.tokenSignin(token, this.props.history);
    } else {
      this.props.history.push(`/${this.props.selectedStudent.url}`);
      //  this.setState({ displaySignin: true });
    }
  };

  submitSignin = (values) => {
    this.props.signinUser(values, this.props.history);
  };

  renderLogout = () => (
    <div className='col-xs-12'>
      <p className='text-center'>You have been signed out of the portal</p>
      <p className='text-center padding-top-two'>
        <button
          type='button'
          className='btn btn-success'
          onClick={() => this.signBackIn()}
        >
          Sign Back In
        </button>
      </p>
      <br />
      <br />

      <div className='text-right small'>
        <a
          className='text-muted'
          onClick={() => localStorage.removeItem("token")}
        >
          Forget Password
        </a>
      </div>
    </div>
  );

  renderSignin = () => (
    <div className='row'>
      <div className='col-md-6 col-sm-8 col-xs-10 col-md-offset-3 col-sm-offset-2 col-xs-offset-1 border-box'>
        <SignInForm onSubmit={this.submitSignin} />
        <div className='text-center'>
          <Link to='password'>Forgot Password?</Link>
        </div>
      </div>
    </div>
  );

  render() {
    const { school, selectedStudent } = this.props;

    return (
      <div>
        <div className='row'>
          <div className='col-sm-12'>
            <div
              className='navigation-style clearfix'
              style={{ backgroundColor: school.schoolColour }}
            >
              <a className='nav-logo pull-left' href='#'>
                {selectedStudent.schoolName}
              </a>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className='col-sm-8 col-sm-offset-2'>
          <div className='panel panel-default'>
            <div className='panel-heading'>
              <h3 className='panel-title'>
                {this.state.displaySignin ? "Signed In" : "Signed Out"}
              </h3>
            </div>
            <div className='panel-body'>
              {this.state.displaySignin
                ? this.renderSignin()
                : this.renderLogout()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Signout.propTypes = {
  signout: PropTypes.func.isRequired,
  school: PropTypes.object.isRequired,
  remember: PropTypes.bool,
  selectedStudent: PropTypes.object.isRequired,
  signinUser: PropTypes.func.isRequired,
  tokenSignin: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    school: state.portal.school,
    selectedStudent: state.selectedStudent,
    remember: state.contact.remember,
  };
}

export default connect(mapStateToProps, actions)(Signout);
