import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { Link } from 'react-router-dom';
import { required, email, number } from '../common/validation';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label className="col-sm-2 control-label">{label}</label>
    <div className="col-sm-9">
         <input className="form-control" {...input} placeholder={label} type={type}/>
            { touched && (error && <div className="admin-label text-right margin-top-quarter"><i className="redBG">{error}</i></div>) }
    </div>
  </div>
);

 const ContactForm = (props) => {
    const { handleSubmit, submitting } = props;

    return (
        <form onSubmit={handleSubmit} className="form-horizontal">
            <h4>Main contact</h4>
            <div className="form-group">
                <Field name="contact1.firstName" type="text" component={renderField} label="First Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="contact1.lastName" type="text" component={renderField} label="Last Name" validate={required} />
            </div>
            <div className="form-group">
                <Field name="contact1.email" type="email" component={renderField} label="Email" validate={[required, email]} />
            </div>
            <div className="form-group">
                <Field name="contact1.mobile" type="text" component={renderField} label="Mobile" validate={[required, number]} />
            </div>
            <div className="form-group">
                <Field name="contact1.address" type="text" component={renderField} label="Address" />
            </div>
            <hr />
            <h4>Second Contact</h4>
            <div className="form-group">
                <Field name="contact2.firstName" type="text" component={renderField} label="First Name"  />
            </div>
            <div className="form-group">
                <Field name="contact2.lastName" type="text" component={renderField} label="Last Name" />
            </div>
            <div className="form-group">
                <Field name="contact2.email" type="email" component={renderField} label="Email" validate={email} />
            </div>
            <div className="form-group">
                <Field name="contact2.mobile" type="text" component={renderField} label="Mobile" validate={number} />
            </div>
            <div className="form-group">
                <Field name="contact2.address" type="text" component={renderField} label="Address" />
            </div>
            <div className="form-group">
                <div className="col-sm-9 col-sm-offset-2">
                    <Field name="contact2.sendEmail" component="input" type="checkbox"/> <span>Send emails to both first and second contacts</span>
                </div>
            </div>
            <hr />
             <div className="form-actions clearfix">
                <div className="col-sm-6">
                    <button type="submit" className="submit-btn" disabled={submitting}>Save</button>
                </div>
                <div className="col-sm-6 text-right">
                    <Link to="new-password">Change Password</Link>
                </div>
           </div>  
        </form>
    );
};

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired
};

ContactForm.propTypes = {
  ...propTypes
};

export default reduxForm({
    form: 'ContactForm'
})(ContactForm);