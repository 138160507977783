import { GET_STUDENT_GROUPS, SET_STUDENT, GET_ENROLMENTS, GET_STUDENT_INFO } from '../actions/types';
import initialState from './initial-state';

export default function(state = initialState.selectedStudent, action) {
    switch(action.type) {

         case SET_STUDENT:
             return action.payload;

        case GET_STUDENT_GROUPS:
             return { ...state, groups: action.groups, enrolments: action.enrolments };

        case GET_ENROLMENTS:
             return { ...state, enrolments: action.enrolments };

        case GET_STUDENT_INFO:
            return { ...state, studentInfo: action.payload };

        default:
            return state;
    }
}